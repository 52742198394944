@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
body {
  background: #161617;
  color: #ffffff;  
  font-family: "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
a {
  text-decoration: none !important;
  color: #f0df72;
  transition: 0.5s;
}
a:hover {
  color: #f0df72;
}
/* Custom Scrollbar */
* {
  box-sizing:border-box;
  font-family:inherit;
  scrollbar-color:#0078ff transparent;
  scrollbar-width:thin
}
body::-webkit-scrollbar {
  display: none;
}
*::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 1px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #0078ff;
}
.border-top-0 {
  border-top: 0 !important;
}
.green-bg {
  background: #f0df72 !important;
  border: 0 !important;
}
.input-group-text {
  border: 0 !important;
  display: inline-flex;
  align-items: center;
  background: #af7707;
  color: #ffffff;
  min-width: 60px;
  justify-content: center;
}
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  display: block;
  margin-bottom: 7px;
}
.fileUploadButton {
  position: relative;
  overflow: hidden;
}
.fileUploadButton input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.profile_dropdown .dropdown-item.active,
.profile_dropdown .dropdown-item:active,
.profile_dropdown .dropdown-item:focus,
.profile_dropdown .dropdown-item:hover {
  background-color: #2c273d !important;
}
.dropdown-toggle::after {
  border-top-width: 0.4em !important;
  border-right-width: 0.4em !important;
  border-left-width: 0.4em !important;
  vertical-align: middle !important;
  color: #a2a7b9;
}
.dropdown-menu {
  background: #231f30;
}
.dropdown-menu li a {
  color: #ffffff;
}
.dropdown-menu li a:hover {
  background: #2c273d !important;
  color: #ffffff;
}
.form-control,
.form-select {
  box-shadow: none !important;
  border: 0 !important;
  outline: none !important;
  padding: 10px 0 10px 10px !important;
}
.input-group-text.select-input-group {
  background: #fff;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}
.input-group-text.select-input-group .form-select {
  min-width: 80px;
}
.btn.btn-green-big {
  background: #af7707;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  border-radius: 50px;
  min-width: 280px;
  padding: 12px 15px;
  margin: 0 auto;
  display: block;
  border: 0 !important;
}
.btn.btn-green-big:hover {
  background: #af7707 !important;
}
.label-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.switch-group {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.switch-group label {
  margin-bottom: 0;
  margin-right: 10px;
}
label.form-check-label span {
  margin-left: 10px;
}
.customTable {
  color: #ffffff;
  border-color: #2d2d5b;
  margin-top: 15px;
  white-space: nowrap;
}
.customTable th {
  font-size: 16px;
  padding: 10px 15px;
  color: #d5d5d5;
}
.customTable td {
  font-size: 16px;
  padding: 10px 15px;
}
.yellowText {
  color: #ffb400 !important;
}
.greenText {
  color: #f0df72 !important;
}
.blueText {
  color: #0091ff !important;
}
.redText {
  color: #ff3636 !important;
}
.greenTxt {
  color: #2bbf89 !important;
}
.greyTxt {
  color: #a2a7b9 !important;
}
.slider_box {
  padding-right: 15px;
}
.flexLabel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.flexLabel label {
  margin-bottom: 0;
}
.flexTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterSelect {
  max-width: 140px;
}
/* Crediantial Styles */
.crediantial-layout {
  padding: 80px 0;
}
.crediantial-layout .navbar-brand {
  margin: 0;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
.crediantial-form {
  background: #231f30;
  padding: 30px;
  border-radius: 20px;
}
.crediantial-form h2 {
  font-size: 34px;
}
.crediantial-form h6 {
  color: #bababa;
  font-size: 16px;
  margin-bottom: 20px;
}
.crediantila-bottom {
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;
}

.crediantial-form .nav-tabs {
  border-bottom: 0;
  margin: 30px 0 25px;
}
.crediantial-form .nav-tabs .nav-link {
  background: #090927;
  color: #008ccc;
  font-size: 20px;
  border-radius: 7px !important;
  border: 0 !important;
  font-weight: 600;
}
.crediantial-form .nav-tabs .nav-link + .nav-link {
  margin-left: 10px;
}
.crediantial-form .nav-tabs .nav-link:hover,
.crediantial-form .nav-tabs .nav-link.active {
  background: #f0df72;
  color: #ffffff;
}
/* User page Styles */
.page-content {
  margin-top: 180px;
  min-height: 90vh;
}
.userContent {
  background: #231f30;
  padding: 30px;
  border-radius: 20px;
}
.navbar.userTopMenu {
  background: #231f30;
}
.form-card {
  border: 2px solid #161617;
  border-radius: 20px;
  padding: 0 30px 15px 30px;
  margin-top: 50px;
}
.form-card h3 {
  margin-top: -12px;
  font-size: 18px;
  margin-bottom: 20px;
}
.form-card h3 span {
  background: #231f30;
  padding: 0 15px;
}
.noteList {
  margin: 0;
  padding: 0 0 20px 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.noteList li {
  margin: 4px 0;
}
.captureFrame * {
  border-radius: 20px;
}
.userContent h2 {
  font-size: 28px;
  margin-bottom: 0;
}
.abv_flx .drpdwn_box {
  margin-bottom: 0;
}

/* Wallet page Styles */
.WalletBalance {
  border-top: 1px solid #2d2d5b;
  padding-top: 15px;
  margin-top: 18px;
}
.wbCard h6 {
  font-size: 18px;
  color: #bababa;
  margin: 0;
  padding: 0;
}
.wbCard h2 {
  font-size: 30px;
  color: #f0df72;
  font-weight: 600;
  margin: 5px 0 5px;
  padding: 0;
}
.wbCard h2 small {
  font-size: 16px;
  color: #ffffff;
}
.wbCard h4 {
  font-size: 18px;
  color: #008ccc;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.qrCodeBox {
  background-color: #ffffff;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}
.copyCodeBox {
  background: #fff;
  color: #000;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
.copyCodeBox p {
  word-break: break-all;
  margin-bottom: 0;
}
.copyCodeBox a {
  min-width: 16px;
  margin-left: 15px;
}
.bankDetailBox ul {
  list-style: none;
  margin: 15px 0 20px;
  padding: 0;
}
.bankDetailBox ul li {
  margin: 0 0 10px 0;
  font-size: 16px;
}
.light_theme .bankDetailBox ul li {
  color: #222222;
}
.bankDetailBox ul li span.bdLabel {
  width: 166px;
  display: inline-block;
}
.bankDetailBox ul li span.bdDetails {
  font-weight: 600;
}
.withdrawDetailBox ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.withdrawDetailBox ul li {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.greenNote {
  color: #f0df72;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid #2d2d5b;
  padding-top: 15px;
  margin-top: 15px;
}
/* User page Top Navigation Styles */
.userTopMenu .nav-link {
  color: #ffffff;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #ffffff;
}
.userTopMenu .navbar-brand {
  color: #ffffff;
}
.topProfileDropdown {
  list-style: none;
  margin: 0;
  padding: 0;
}
.navbar-toggler {
  color: #fff !important;
  background: #f0df72 !important;
}
.navbar-toggler span {
  color: #fff !important;
}
/* Profile page side menu */
.profileSideMenu {
  background: #231f30;
  border-radius: 20px;
  padding: 15px;
  position: sticky;
  top: 115px;
}
.profileSideMenuTop {
  padding: 15px;
  border-bottom: 1px solid #2d2d5b;
}
.profileSideMenuTop p a {
  font-size: 16px;
  color: #bababa;
  width: 180px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
.kyc_verify {
  display: flex;
  align-items: center;
  padding: 8px 0 2px;
}
.kyc_verify span {
  color: #f0df72;
  font-size: 14px;
}
.profileSideMenuTop p small {
  font-size: 14px;
  color: #bababa;
}
/* .profileSideMenuBottom {
  padding: 15px;
} */
.profileSideMenu .navbar-nav .nav-item {
  margin: 3px 0px;
}
.profileSideMenu .navbar-nav .nav-item a {
  display: flex;
  align-items: center;
}
.profileSideMenu .navbar-nav .nav-item a:hover {
  color: #f0df72;
}
.profileSideMenuBottom .nav-link img {
  max-width: 15px;
  margin-right: 15px;
}

/* Wallet page side menu */
.walletSideMenuBottom .nav-item {
  margin-bottom: 15px;
}
.walletSideMenuBottom .nav-item .nav-link {
  background: #231f30;
  padding: 15px 15px;
  border-radius: 15px;
  font-size: 18px;
  color: #ffffff;
  transition: 0.5s;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.walletSideMenuBottom .nav-item .nav-link.active,
.walletSideMenuBottom .nav-item .nav-link:hover {
  background: #2a3457;
}
.walletMenuIcon1 {
  background: url(../images/walletMenuIcon-1.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.walletMenuIcon2 {
  background: url(../images/walletMenuIcon-2.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.walletMenuIcon3 {
  background: url(../images/walletMenuIcon-3.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.walletMenuIcon4 {
  background: url(../images/walletMenuIcon-4.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
/* Dashboard page side menu form */
.dashboardContent {
  background: #0d0d30;
  padding: 0px;
  border-radius: 10px;
}
.dashboardContentTitleBar {
  background-color: #191d42;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dashboardContentTitleBar h3 {
  margin-bottom: 0;
  font-size: 18px;
}
.chartTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #2d2d5b;
  border-bottom: 1px solid #2d2d5b;
  padding: 15px 0;
  margin: 15px 0;
}
.chartTitle h4 {
  margin: 0;
}
.chartTitle .form-select {
  min-width: 130px;
}
.dashboardBalanceDetails h2 {
  font-size: 18px;
  color: #dddddd;
  font-weight: 600;
  line-height: 26px;
}
.dashboardBalanceDetails h2 span {
  color: #ffffff;
  font-size: 14px;
  margin-top: 7px;
  font-weight: 500;
}
.dashboardTwoColumn {
  margin-bottom: 25px;
}
.dashboardTwoColumn .userContent {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dashboardTabs {
  border-bottom: 0 !important;
  flex-wrap: nowrap;
}
.dashboardTabs li {
  max-width: 33%;
}
.dashboardTabs li button {
  border-radius: 0 !important;
  background: transparent !important;
  border-bottom-color: transparent !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom-width: 2px !important;
  color: #8b8b90 !important;
  white-space: nowrap;
}
.dashboardTabs li button.active {
  border-bottom-color: #008ccc !important;
  color: #ffffff !important;
}

.dashboardPairDetails .slick-arrow {
  position: absolute;
  top: -52px;
}
.dashboardPairDetails .slick-arrow.slick-prev {
  left: auto !important;
  right: 20px;
}
.dashboardPairDetails .slick-arrow.slick-next {
  left: auto !important;
  right: -10px;
}
.pairCard {
  padding: 0 8px;
}
.pairCard .pair_table_bodyrow {
  border: 1px solid #2d2d5b;
  border-radius: 5px;
  padding: 15px;
}
.pairCard .pair_table_bodyrow_bottom_left > div {
  margin-top: 5px;
}
/* Wallet page side menu form */
.walletForm {
  border-top: 1px solid #2d2d5b;
  padding-top: 15px;
  margin-top: 18px;
}

/* Footer style css */
.userFooter {
  background: #231f30;
  padding: 15px 0;
  margin-top: 30px;
}
.userFooter p {
  font-size: 14px;
}

/* Trade Page Css Starts */
.trade_wrapper {
  margin-top: 62px;
}
.trade_topbar {
  background: #231f30;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.trade_topbar p {
  color: #9c9c9c;
  font-size: 14px;
  margin-bottom: 0;
}
.trade_topbar h3 {
  color: #fff;
  font-size: 20px;
  margin: 5px 0;
}
.trade_topbar h6 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
.trade_topbar > div {
  padding: 10px 0;
}
.trade_topbar > div:first-child {
  background: #151540;
  padding: 10px 30px;
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  min-height: 80px;
}
.trade_topbar > div:first-child img {
  width: 24px;
  margin-right: 5px;
}
.trade_topbar > div:last-child {
  padding: 10px 30px;
}
.trade_chart {
  border-radius: 8px;
  width: 100%;
  background: #2c273d;
  padding: 5px;
  height: 100%;
  position: relative;
}
/* .trade_chart img {
  width: 100%;
  height: 560px;
  object-fit: fill;
  object-position: center;
  border-radius: 10px;
} */
div#tv_chart_container {
  height: 100%;
}
nav.navbar.userTopMenu.trade_menu {
  background: #231f30;
}
.trade_main { 
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  max-width: 1260px;
  height: 100%;
  min-height: 0;
  margin: 0 auto;
  position: relative;
}
.trade_main_left_top {
  /* display: flex;
  align-items: flex-start;
  height: 100%; */
  overflow: hidden;
  border-radius: 8px;
  grid-area: chart;
}
.trade_main_left {
  width: 60%;
  height: 100%;
}
.coin_details {
  width: 300px;
  position: relative;
  z-index: 100;
  background: #231f30;
  border-radius: 8px 8px 0 0;
  height: 100%;
}
.trade_main_settings.cardheadbg {
  width: 300px;
}
/* .coin_details .card-body {
  position: absolute;
  width: 100%;
  top: 37px;
} */
.trade_card {
  border: none;
  background: transparent;
}
.trade_card .card-header {
  background: #2c273d;
}
.trade_card .card-header h2 {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  cursor: default;
}
.trade_card .card-body {
  padding: 0;
  background: #231f30;
  border-radius: 0 0 8px 8px;
}
.trade_card .card-body.card-body-padding {
  padding: 10px;
}
.trade_card ul.nav-tabs {
  background: #151540;
  border: none;
  padding-left: 8px;
}
.trade_card .nav-tabs .nav-link {
  border: none;
  border-radius: 8px;
  color: #a2a7b9;
  font-size: 12px;
}
.trade_card .nav-tabs .nav-item.show .nav-link,
.trade_card .nav-tabs .nav-link.active,
.trade_card .nav-tabs .nav-link:focus,
.trade_card .nav-tabs .nav-link:hover {
  /* background: #0d0d30; */
  border: none;
  color: #fff;
}
input.form-control.search_inp {
  border: 1px solid #333545 !important;
  background: transparent;
  color: #fff;
  font-size: 14px;
}
.search_inp_div {
  position: relative;
  padding: 0 10px;
  margin-top: 15px;
}
.search_inp_div .bi-search {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #8f8f91;
}
.pair_table_head {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #333545;
}
.pair_table_head > div:first-child {
  width: 120px;
}
.pair_table_head > div:nth-child(2) {
  width: 80px;
}
.pair_table_head > div:nth-child(3) {
  width: 80px;
}
.pair_table_head_batch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 30px 10px 10px;
  border-bottom: 1px solid #23274c;
}
.pair_table_head_batch > div {
  font-size: 14px;
  color: #fff;
  position: relative;
}
.light_theme .pair_table_head_batch > div {
  color: #333333;
}
.pair_table_head > div {
  font-size: 12px;
  line-height: 14px;
  color: #a2a7b9;
  position: relative;
  cursor: default;
}
/* .pair_table_head > div:nth-child(2):after,
.pair_table_head_batch > div::after {
  content: "";
  position: absolute;
  top: 3px;
  right: -15px;
  background: url("../images/sort_icon.png") no-repeat;
  background-size: 65%;
  width: 9px;
  height: 12px;
}
.pair_table_head > div:nth-child(2):after {
  right: 10px;
} */
.pair_table_body {
  padding: 10px;
  /* height: 550px;
  overflow: auto; */
}
.pair_table_bodyrow_top img {
  width: 16px;
  margin-right: 5px;
}
.pair_table_bodyrow_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap;
}
.pair_table_bodyrow_top > div:first-child {
  display: flex;
  align-items: center;
}
.pair_table_bodyrow_top > div:nth-child(2) {
  display: flex;
  align-items: center;
}
.pair_table_bodyrow_top > div:first-child h3 {
  font-size: 14px;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 14px;
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: inherit;
}
.pair_table_bodyrow_top > div:first-child h3 span {
  font-size: 14px;
  color: #a2a7b9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
  display: inline-block;
  margin-left: 3px;
}
.bg_green {
  background-color: #039c70 !important;
}
.bg_red {
  background-color: #d7615b !important;
}
.bg_blue {
  background-color: #cde9f6 !important;
  color: #3a6788;
}
.bg_yellow {
  background-color: #f8f4c3 !important;
  color: #785d20;
}
.bg_grey{
  background-color: rgba(255,255,255,.1);
}
.trade_type {
  font-size: 12px;
  font-weight: 400;
  padding: 3px 5px;
  height: 18px;
}
.pair_table_bodyrow_top > div .bi {
  font-size: 15px;
  margin-left: 8px;
  color: #f0df72;
}
.per_change {
  font-size: 10px;
  font-weight: 400;
  padding: 4px;
}
.pair_table_bodyrow_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 12px;
  /* margin-top: 8px; */
}
.pair_table_bodyrow_bottom label {
  font-size: 14px;
  color: #9d9fc3;
}
.pair_table_bodyrow_bottom_left > div label:nth-child(2),
.pair_table_bodyrow_bottom_right > div label:nth-child(2) {
  color: #fff;
}
.pair_table_bodyrow_bottom_left,
.pair_table_bodyrow_bottom_right {
  width: 56px;
}
/* .pair_table_bodyrow_bottom_left > div,
.pair_table_bodyrow_bottom_right > div {
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
} */
.pair_table_bodyrow_bottom_right span small {
  font-size: 14px;
}
.pair_table_bodyrow {
  /* border-bottom: 1px solid #23274c; */
  padding: 10px 8px 10px 8px;
  cursor: pointer;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* margin-right: 10px; */
}
.pair_table_bodyrow.active,
.pair_table_bodyrow:hover {
  background: #2b273b;
}
.batch_settings {
  background: #191d42;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 5px 5px;
}
.batch_settings p {
  font-size: 14px;
  color: #9698bc;
  margin: 0;
  padding: 2px 10px;
  cursor: pointer;
}
.choose_pair img {
  width: 16px;
  margin-right: 5px;
}
.choose_pair {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
.choose_pair p {
  margin: 0;
  font-size: 14px;
}
.rdt_TableCol_Sortable span {
  position: relative;
  text-indent: -999px;
  opacity: 1;
  transform: unset;
}
.rdt_TableCol_Sortable span::before {
  content: "";
  background: url("../images/sort_icon.png") no-repeat;
  width: 9px;
  height: 12px;
  position: absolute;
  right: -12px;
  top: 4px;
}
.trade_main_left_bottom {
  min-height: 540px;  
  height: 600px;
  border-radius: 8px 8px 0 0;
  background-color: #231f30;
}
/* .trade_main_left_bottom .trade_card.trade_history_card .card-body {
  height: 100%;
  min-height: 76vh;
} */
.trade_history_card ul.nav-tabs {
  background-color: #221f2f;
  padding: 0;
  display: flex;
  height: 40px;
  padding: 3px;
  border: 1px solid #333545; 
  border-radius: 8px;  
}
.trade_history_card .nav-tabs .nav-link:focus,
.trade_history_card .nav-tabs .nav-link:hover {
  color: #fff;
  background-color: transparent;
}
.trade_history_card .nav-tabs .nav-item.show .nav-link,
.trade_history_card .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #333545;
}
.trade_history_card .card-header {
  padding: 10px 20px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade_history_card .nav-tabs .nav-link {
  height: 32px;
  min-height: auto;  
  color: #a2a7b9;
  padding: 9px 12px;
  border-radius: 8px;
  font-size: 12px;
}
.primary_table .rdt_TableHeadRow,
.primary_table .rdt_Pagination,
.rdt_Pagination {
  background-color: #231f30 !important;
  color: #959596 !important;
  font-size: 14px !important;
}
.primary_table .rdt_TableHeadRow {
  border-bottom: 1px solid transparent !important;
}
.primary_table .rdt_Pagination {
  border-top: 1px solid transparent !important;
  border-radius: 0 0 10px 10px;
}
.primary_table .rdt_TableRow {
  /* background-color: #2c273d !important; */
  background-color: #2c273d ;
  color: #fff !important;
  font-size: 14px !important;
  border-bottom: none !important;
  min-height: 60px;
  cursor: pointer;
  position: relative;
}

.primary_table .rdt_Pagination button:disabled,
.rdt_Pagination button:disabled {
  color: rgb(255 255 255 / 18%) !important;
  fill: rgb(255 255 255 / 18%) !important;
}
.primary_table .rdt_Pagination button,
.rdt_Pagination button {
  color: rgb(255 255 255 / 54%) !important;
  fill: rgb(255 255 255 / 54%) !important;
}
.light_theme .primary_table {
  color: rgb(0 0 0 / 18%) !important;
  fill: rgb(0 0 0 / 18%) !important;
}
.light_theme .primary_table .rdt_Pagination button {
  color: rgb(0 0 0 / 54%) !important;
  fill: rgb(0 0 0 / 54%) !important;
}
.trade_main_right {
  /* width: 300px;
  margin-left: 4px;
  height: 100%; */
  display: grid;
  grid-gap: 4px;
  grid-area: column;
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  background: #231f30;
  border-radius: 8px 8px 0 0;
}
.trade_main_right .trade_card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.trade_main_right .trade_card .card-header p {
  color: #959596;
  font-size: 14px;
  margin: 0;
}
.card_body_padding {
  padding: 10px;
}
.trade_main_right .trade_card .form-group {
  margin-bottom: 8px;
}
.trade_main_right .trade_card .form-group label {
  margin-bottom: 2px;
}
.f_16 {
  font-size: 16px;
}
.trade_options_card {
  display: flex;
  flex-wrap: wrap;
  background: #0d0d30;
  margin-top: 8px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.trade_options_card div {
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.trade_options_card div h3 {
  font-size: 16px;
  margin: 10px 0 0;
}
.trade_options_card div:nth-child(1),
.trade_options_card div:nth-child(3) {
  border-right: 1px solid #23274c;
}
.trade_options_card div:nth-child(1),
.trade_options_card div:nth-child(2) {
  border-bottom: 1px solid #23274c;
}
.strategy_btn {
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #008ccc;
  border: 2px solid #008ccc;
  font-size: 18px;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  transition: 0.2s all;
}
.strategy_btn:hover {
  background: transparent;
}
.strategy_settings_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-bottom: 1px solid #23274c;
}
.strategy_settings_div div {
  display: flex;
  align-items: center;
}
.strategy_settings_div div img {
  margin-right: 15px;
}
.strategy_settings_div h5 {
  font-size: 16px;
  margin: 0;
  max-width: 65%;
}
.strategy_settings_div h6 {
  font-size: 16px;
  color: #9698bc;
  margin: 0;
}
.strategy_settings_panel {
  background: #0d0d30;
  border-radius: 10px;
}
.strategy_settings_panel .strategy_settings_div:last-child {
  border-bottom: 0;
}
.primary_btn {
  background-color: #af7707;
  border: 2px solid #af7707;
  margin: 10px 0 0;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  min-height: 48px;
  padding: 8px 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.primary_btn:hover {
  background-color: transparent;
}

.danger_alert {
  background-color: #a32610;
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
}

.danger_alert p {
  margin: 0;
  font-size: 16px;
  color: #ffffff;
  margin-left: 10px;
  line-height: 18px;
}

.danger_alert.alert-dismissible .btn-close {
  background: url("../images/alert_close_icon.png") center/1em auto no-repeat;
  opacity: 1;
  padding: 12px 30px 14px 10px;
}

.info_alert {
  background-color: #e6effa;
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
}

.info_alert p {
  margin: 0;
  font-size: 16px;
  color: #05051f;
  margin-left: 10px;
  line-height: 18px;
}
.info_alert.alert-dismissible .btn-close {
  background: url("../images/alert_close_icon.png") center/1em auto no-repeat;
  opacity: 1;
  padding: 12px 30px 14px 10px;
}
.saved_strategies_panel h2 {
  font-size: 20px;
  margin: 20px 0 0;
}
.saved_strategies_panel button {
  margin: 10px 0;
  background: #191d42;
  border: 1px solid #23274c;
  padding: 10px 10px 10px 20px;
  border-radius: 10px;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 20px;
  text-align: left;
  transition: 0.2s all;
}
.saved_strategies_panel button:hover {
  background: #23274c;
  border: 1px solid #191d42;
}
.strategy_settings_close {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}
.new_strategy_settings_panel > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #23274c;
}
.new_strategy_settings_panel > div:nth-child(1),
.new_strategy_settings_panel > div:nth-child(2) {
  display: block;
}
.new_strategy_settings_panel > div:last-child {
  border-bottom: 0;
}
.new_strategy_settings_panel > div a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new_strategy_inp {
  width: 60px;
  height: 34px;
  padding: 5px 10px 5px 10px !important;
}
.new_strategy_settings_panel h6 {
  color: #9698bc;
  margin: 0;
}
.edit_indicators {
  color: #008ccc;
  cursor: pointer;
  font-size: 16px;
  margin-right: 15px;
}
.edit_indicators .bi {
  font-size: 14px;
}
.new_strategy_settings_panel label {
  font-size: 16px;
}
.new_strategy_settings_panel label.label_large {
  font-size: 20px;
}
#custom_amt_setup i {
  color: #008ccc;
}
.modal-backdrop.show {
  opacity: 0.78;
}
.primary_modal .modal-content {
  background-color: #231f30;
  border-radius: 8px;
}
.primary_modal .modal-header {
  border-bottom: none;
  justify-content: center;
}
.primary_modal .modal-title {
  letter-spacing: .25px;
  font-size: 17px !important;
  font-weight: 600;
  line-height: 20px;
}
.primary_modal .btn-close {
  background: url("../images/close_icon.png") center/24px auto no-repeat;
  opacity: 1;
  width: 18px;
  height: 18px;
  box-shadow: none;
  background-size: 50%;
  position: absolute;
  right: 12px;
  transition: 0.2s all;
}
.primary_modal .btn-close:hover {
  filter: brightness(1);
}
.primary_modal .modal-body p {
  color: #a2a7b9;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
nav.trade_menu ul.topProfileDropdown {
  display: flex;
  align-items: center;
}
nav.trade_menu ul.topProfileDropdown li {
  padding: 0 15px;
}
nav.trade_menu ul.topProfileDropdown li a {
  color: #fff;
}
.menu_round_btn {
  width: 35px;
  height: 35px;
  background: #2c283d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.notification_link {
  position: relative;
}
.notification_link sup {
  position: absolute;
  right: -3px;
  top: -8px;
  background: #e90c15;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
}
.theme_button.menu_round_btn {
  background: #008ccc;
  cursor: pointer;
}
.new_bot_btn {
  background-color: #fd9827;
  border: 2px solid #fd9827;
  border-radius: 3px;
  color: #fff !important;
  padding: 0.5px 8px;
  transition: 0.2s all;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 5px;
}
.new_bot_btn svg {
  width: 20px;
  color: #fff;
  margin-left: -5px;
}
.new_bot_btn:hover {
  background-color: #ffb96b;
  border: 2px solid #ffb96b;
}
.profile_dropdown {
  margin-top: 8px !important;
  padding: 0;
}
/* .profile_dropdown::after {
  content: " ";
  position: absolute;
  right: 6px;
  top: -10px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #231f30;
} */
.profile_dropdown li {
  padding: 0 !important;
  border-bottom: 1px solid #2b273b;
}
.profile_dropdown li a {
  padding: 8px 15px;
  font-size: 14px;
  display: flex !important;
  align-items: center;
}
.profile_dropdown li:last-child {
  border-bottom: 0;
}
.profile_dropdown li a img,
.profile_dropdown li label img {
  margin-right: 10px;
  max-width: 15px;
  filter: brightness(0) invert(1);
}
.trade_sidemenu {
  background-color: transparent;
  position: relative;
  top: unset;
  padding: 0;
  flex-grow: unset;
}
.topProfileDropdown_mobile {
  display: none !important;
}
.demo_list label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #fff !important;
  cursor: pointer;
  padding: 8px 15px;
}
.demo_list label:hover {
  background: #007938 !important;
}
.d_d_none {
  display: none !important;
}
.trade_sidemenu .navbar-nav .nav-item a:hover {
  color: #fff;
}
.new_bot_panel > a {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 12px;
  background: #2c273d;
  border: 1px solid #2c273d;
  padding: 30px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  gap: 12px;
}
.new_bot_panel > a .new_bot_panel_content {
  display: flex;
  align-items: center;
}
.new_bot_panel_content svg {
  width: 36px;
  height: 36px;
}
.new_bot_panel_content h4 {
  letter-spacing: .25px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  margin: 0 0 0 15px;
  color: #ffffff;
  white-space: nowrap;
}
.new_bot_panel p {
  color: #a2a7b9;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
}
.icon_start {
  opacity: 0.45;
}
.new_bot_panel > a:hover {
  background: #312b45;
}
.new_bot_panel > a:hover .icon_start {
  opacity: 1;
}
#mobileMenuDrop a {
  background: #0d0d30;
  display: flex;
  padding: 15px;
  border-radius: 15px;
  font-size: 20px;
  color: #fff;
  transition: 0.5s;
  align-items: center;
  justify-content: space-between;
}
#mobileMenuDrop a:hover,
#mobileMenuDrop a.active {
  background: #008ccc;
}
#mobileMenuDrop a.active::after {
  content: "\F119";
  font-family: bootstrap-icons !important;
}
#mobileMenuDrop ul {
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
}
#mobileMenuDrop ul li {
  margin-top: 15px;
}
#mobileMenuDrop ul li a {
  background: #0d0d30;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 15px;
  font-size: 20px;
  color: #fff;
}
#mobileMenuDrop ul li a:hover {
  background: #0078ff;
}
.flexIconLeft {
  display: inline-flex;
  align-items: center;
}
button.primary_btn.primary_blue_btn {
  background-color: #0078ff;
  border: 2px solid #0078ff;
}
button.primary_btn.primary_blue_btn:hover {
  background-color: #2f91ff;
  border: 2px solid #2f91ff;
}
button.primary_btn.primary_black_btn {
  background-color: #323545;
  border: 2px solid #323545;
}
button.primary_btn.primary_black_btn:hover {
  background-color: #404457;
  border: 2px solid #404457;
}
#custom_amt_setup,
#custom_amt_setup label {
  cursor: pointer;
}
.inp_grp_width {
  width: 52px;
  justify-content: center;
}
.custom_amt_setup_div label {
  font-size: 16px;
}
.custom_amt_setup_div .row {
  padding: 5px 0;
}
.custom_amt_setup_back {
  cursor: pointer;
}
.primary_accordian .accordion-item {
  margin: 10px 0;
  background-color: #151540;
  border: 1px solid #1e2247;
  border-radius: 5px;
}
.primary_accordian .accordion-button {
  background-color: transparent;
  border: none;
  border-radius: 0 !important;
  color: #fff;
  font-size: 16px;
  box-shadow: none;
  padding: 10px;
}
.indicators_panel div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #191b43;
}
.indicators_panel div:nth-child(1) {
  border-top: 1px solid #191b43;
}
.indicators_panel div label {
  font-size: 16px;
  color: #9698bc;
}
.indicators_inp {
  width: 156px;
  padding: 3px 10px !important;
}
.accordion-button::after {
  content: "\F4FE";
  font-family: bootstrap-icons !important;
  border-radius: 50%;
  background: rgb(0, 96, 170);
  background: linear-gradient(
    100deg,
    rgba(0, 96, 170, 1) 0%,
    rgba(0, 152, 70, 1) 100%
  ) !important;
  border: 1px solid #120b3b;
  color: #151540;
  width: 25px;
  height: 25px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion-button:not(.collapsed)::after {
  content: "\F229";
  transform: unset;
  font-size: 14px;
  line-height: 32px;
}
.batch_settings_modal_panel {
  display: flex;
}
#batch_setting_modal .modal-dialog {
  min-width: 800px;
}
.batch_settings_modal_panel .coin_details {
  border: 1px solid #23274c;
  border-radius: 6px;
  min-width: 42%;
  margin-right: 20px;
}
.batch_settings_modal_panel .coin_details .form-check-input[type="checkbox"] {
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 12px;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #23274c;
  width: 20px;
}
.batch_settings_modal_panel .coin_details .pair_table_bodyrow {
  width: 100%;
  padding-right: 15px;
}
.batch_settings_modal_right {
  width: 58%;
}
.operate_settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.operate_settings h3,
.operate_settings label {
  font-size: 18px;
  margin: 0;
}
.operate_settings .form-check-input[type="checkbox"] {
  border-radius: 50%;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #23274c;
}
.batch_settings_modal_panel
  .coin_details
  .form-check-input:checked[type="checkbox"],
.operate_settings .form-check-input:checked[type="checkbox"] {
  background-color: #2196f3;
}
.batch_settings_btn_grp > div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.batch_settings_btn_grp button {
  background: #191d42;
  border: 1px solid #191d42;
  padding: 6px 10px 6px 15px;
  border-radius: 10px;
  display: block;
  width: 50%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  transition: 0.2s all;
}
.batch_settings_btn_grp > div button:nth-child(2) {
  margin-left: 20px;
}
.batch_settings_btn_grp button img {
  margin-right: 10px;
}
.batch_settings_btn_grp {
  margin-top: 15px;
}
/* Trade Page Css Ends */

.message_icon {
  background: url(../images/message_icon.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 23px;
  height: 18px;
  margin-right: 15px;
}
.notification_panel {
  margin-top: 10px;
}
.notification_panel h4 {
  font-size: 14px;
  margin: 0;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.notification_panel h2 {
  display: flex;
  align-items: center;
  height: 36px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
  color: rgba(255,255,255,.5);
  margin: 15px 0 0;
}
.notification_panel h5 {
  color: rgba(255,255,255,.5);
  font-size: 15px;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  grid-column: 4;
  grid-row: 1/span 2;
}
.notification_panel_single {
  background-color: #231f30;
  position: relative;
  display: grid;
  align-items: start;
  padding: 12px 25px;
  font-size: 15px;
  grid-template-columns: 30px .5fr 2fr .5fr;
  grid-template-rows: 1fr;
  margin-bottom: 10px;
}
.notification_panel_single > div {
  display: flex;
  align-items: center;
  height: 100%;
  grid-column: 1;
  grid-row: 1/span 2;
}
.notification_panel_single img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.notification_panel_single h3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  grid-column: 2;
  grid-row: 1/span 2;
  font-size: 15px;
  margin: 0;
}
.api_binding_tab {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.api_binding_tab .nav-item .nav-link {
  background: #171424;
  border: 1px solid #171424;
  min-height: 250px;
}
.api_binding_tab .nav-item .nav-link.active {
  background: #fd9827
}
.api_binding_tab .nav-item .nav-link.active img {
  filter: brightness(0) invert(1);
}
.api_binding_card {
  background: #1c1925;
  border-radius: 10px;
  padding: 30px;
}
.api_binding_card_title {
  border-bottom: 1px solid #2d2d5b;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.api_binding_card_title h3 {
  font-size: 28px;
  margin: 0;
}
.api_binding_card_title h3 span {
  font-size: 16px;
}
.api_binding_card_title a {
  font-size: 16px;
}
.api_binding_card_content ol li {
  font-size: 16px;
}
.profileSideMenuBottom a.nav-link.active {
  color: #f0df72;
}
.edit_btn {
  background: #2d2d5b;
  border: 1px solid #2d2d5b;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  padding: 4px 12px;
}
.edit_btn i {
  font-size: 12px;
  margin-right: 3px;
}
.edit_btn:hover {
  background: #f0df72;
  border-color: #f0df72;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.panic_btn {
  background: #e90c15;
  border: 2px solid #e90c15;
  border-radius: 3px;
  color: #fff;
  padding: 1px 20px;
  text-transform: uppercase;
  transition: 0.2s all;
  position: relative;
}
.panic_btn img {
  position: absolute;
  left: -20px;
  top: -2px;
  filter: unset !important;
}
.member_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #191d42;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}
.member_box > div:nth-child(1) {
  display: flex;
  align-items: center;
}
.member_box > div:nth-child(1) img {
  margin-right: 8px;
}
.member_box > div:nth-child(1) h4 {
  font-size: 18px;
  margin: 0;
}
.member_box p {
  font-size: 14px;
  color: #9f9f9f;
}
.member_box h5 {
  font-size: 16px;
  margin: 0;
}
.renew_btn {
  background: #d8625b;
  border: 1px solid #d8625b;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  padding: 1px 20px;
}
.renew_btn:hover {
  background: transparent;
  color: #d8625b;
  border: 1px solid #d8625b;
}
.flexTitle .dashboardTabs {
  width: 70%;
}
.contact_sec p,
.contact_sec p a {
  color: #fff;
  font-size: 18px;
}
.contact_sec p span {
  color: #9c9c9c;
}
.tfa_status {
  background-color: #161617;
  border-radius: 5px;
  padding: 12px 20px;
  margin-bottom: 20px;
}
.tfa_status p {
  font-size: 18px;
  color: #bababa;
}
.tfa_status p span {
  margin-left: 5px;
}
.notes_section h5 {
  color: #bababa;
  font-size: 18px;
}
.notes_section p {
  font-size: 18px;
}
.notes_section p a {
  color: #f0df72;
}
.notes_section p a:hover {
  text-decoration: underline !important;
}
.notes_section ul {
  list-style: none;
}
.notes_section ul li {
  position: relative;
}
.notes_section ul li::before {
  content: "";
  background-color: #b9b9be;
  width: 5px;
  height: 2px;
  position: absolute;
  left: -20px;
  top: 14px;
}
.video_tutorials_panel {
  margin: 30px 0;
}
.video_tutorials_panel h3 {
  font-size: 18px;
  background: #008ccc;
  margin: -8px 0 0;
  padding: 10px 15px;
  border-radius: 0 0 12px 12px;
}
.video_tutorials_panel iframe {
  border-radius: 12px 12px 0 0;
}
.referral_panel {
  text-align: center;
  border: 2px solid #2d2d5b;
  border-radius: 20px;
  padding: 50px 40px;
  margin: 30px 0;
}
.referral_panel .input-group {
  max-width: 50%;
  margin: 0 auto;
}
.referral_panel .form-group {
  margin-top: 30px;
}
.referral_panel h3 {
  font-size: 36px;
  margin: 15px 0 30px;
}
.referral_panel h4 {
  font-size: 18px;
  color: #6d77ff;
  display: inline-block;
  margin: 0 15px 0 0;
}
.trade_card .card-header h2 img {
  width: 32px;
  margin-right: 5px;  
}
.primary_modal.share_modal .modal-content {
  background: #0d0d30 url("../images/share_modal_bg.png") no-repeat;
  background-size: cover;
}
.share_modal .modal-body .share_modal_body_top {
  display: flex;
  align-items: center;
}
#share_modal .trade_type {
  font-size: 18px;
  height: auto;
  padding: 6px 12px;
  margin-right: 15px;
}
.share_trade_details_panel > div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #23274c;
  padding: 15px 0;
}
.share_trade_details_panel > div:last-child {
  border-bottom: none;
}
.share_trade_details_panel > div h3 {
  font-size: 32px;
  margin-bottom: 0;
  margin-right: 15px;
}
.share_trade_details_panel > div h4 {
  font-size: 18px;
}
.share_trade_details_panel > div h4 span {
  display: block;
}
.share_trade_details_panel > div h5 label,
.share_trade_details_panel > div label {
  margin: 0;
  color: #9d9fc3;
  font-size: 16px;
}
.share_trade_details_panel > div h5 span {
  font-size: 16px;
}
.share_trade_details_panel > div h2 {
  font-size: 24px;
}
.share_trade_details_panel > div h5 label {
  width: 140px;
}
.share_modal .modal-body .share_modal_body_left {
  width: 50%;
}
.share_modal .modal-body .share_modal_body_right {
  width: 50%;
  text-align: center;
}
.share_modal .modal-dialog {
  max-width: 600px;
}
.share_modal_body_bottom {
  border-top: 1px solid #23274c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 10px;
}
.social_share_list {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}
.social_share_list li {
  margin-right: 20px;
}
.revenue_flex {
  display: flex;
  justify-content: end;
  background: #189846;
  padding: 12px 15px;
  border-radius: 5px;
}
.revenue_flex h4 {
  font-size: 18px;
  margin: 0;
}
.revenue_flex h4:nth-child(1) {
  margin-right: 50px;
}
img.share_icon {
  margin: -3px 0 0 2px;
}
.react-tel-input .flag-dropdown {
  border-radius: 5px 0 0 5px !important;
  z-index: 10 !important;
  background-color: #fff !important;
}
.react-tel-input .form-control {
  padding: 10px 0 10px 50px !important;
  height: unset !important;
  border-radius: 5px !important;
}
.auth_otp_btn button.new_bot_btn {
  padding: 5px;
  text-transform: capitalize;
  cursor: pointer;
}
.auth_otp_btn button.new_bot_btn:hover {
  background-color: #008ccc;
}
.auth_otp_btn {
  position: absolute;
  top: -49px;
  right: 2px;
  z-index: 10;
}
.react-tel-input .country-list .search-box {
  padding: 8px 8px !important;
  width: 85% !important;
  text-transform: capitalize;
}
.react-tel-input .country-list .country-name {
  color: #333;
}
.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background-color: rgb(0 152 70 / 20%) !important;
}
.invite_btn {
  background: transparent;
  border: 0;
}
/* .track_vertical {
  background-color: #1d2146;
} */
.thumb_vertical {
  background-color: #0078ff;
}
h2.kyc_success {
  text-align: center;
}

/* ID Card Css */
.id_wrapper {
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
}
.id_card {
  background: #000000 url("../images/idcard_bg.png") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  margin: 20px;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  padding: 0 20px 0;
}
img.logo {
  display: block;
  text-align: center;
  margin: 15px auto;
}
.id_img {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.id_img img {
  width: 220px;
  border-radius: 30px;
  border: 5px solid #f0df72;
  height: 250px;
  object-fit: cover;
  object-position: top;
}
.id_card h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
}
.user_info {
  margin: 10px 0;
}
.user_info h3 {
  font-size: 24px;
  margin: 0;
}
.user_info h4 {
  font-size: 18px;
  margin: 0 0 5px;
}
.user_details {
  margin: 0px auto 40px;
  padding-bottom: 20px;
  position: relative;
}
.user_details::after {
  content: "";
  background-color: #fff;
  width: 56%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.user_details h5 {
  color: #e8e8e8;
  font-weight: 500;
  margin: 0 0 4px;
  font-size: 18px;
}
.shape_bottom {
  margin-bottom: -5px;
}
.print_btn button {
  background: #f0df72;
  color: #fff;
  border: 1px solid #f0df72;
  padding: 10px 40px;
  font-size: 18px;
  border-radius: 8px;
  margin-top: 20px;
}
@media print {
  body {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .id_card {
    background: #000000 url("../images/idcard_bg.png") no-repeat !important;
    print-color-adjust: exact;
    transform: scale(0.65);
  }
  .print_btn,
  nav.navbar.userTopMenu.trade_menu,
  .userFooter {
    display: none;
  }
  .id_img img {
    width: 220px;
    height: 250px !important;
    min-height: 250px !important;
    object-fit: cover;
    object-position: top;
  }
  .page-content {
    margin-top: 0;
    min-height: 100%;
  }
}
.input-group-text.inp_grp_width a {
  color: #fff;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.MuiInput-underline:after {
  display: none !important;
}
.profileSideMenuTop_eye {
  display: flex;
  align-items: center;
}
.profileSideMenuTop .profileSideMenuTop_eye i {
  margin-left: 15px;
}
.rdt_Table > div {
  color: #fff !important;
  background-color: #231f30 !important;
}
.chart_mobile_head {
  display: none;
}
.loader {
  border: 14px solid #f3f3f3;
  border-top: 14px solid #3498db;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  margin: 10px auto;
}
/* .loader {
  margin: 0 auto;
  background: url("../images/loadergain.gif");
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: 100%;
  margin: 0 auto;
} */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.profile_email {
  display: flex;
  align-items: center;
}
button.primary_btn.btn-danger {
  background: #dc3545;
  border-color: #dc3545;
}
button.primary_btn.btn-danger:hover {
  background-color: transparent;
}
#share_page_modal {
  width: 50%;
  margin: 50px auto;
}
.share_download_pdf {
  visibility: hidden;
  max-height: 0;
  position: absolute;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  z-index: -999;
}
.custom_tooltip {
  background-color: #2b273c !important;
  border: 1px solid #333545;
  opacity: 1;
  z-index: 999;
  min-width: 200px;
}
.userTopMenu .navbar-brand img {
  width: 150px;
}
.logo_beforeauth .navbar-brand img {
  width: 200px;
}
.direction_badge_wrap {
  width: 80px;
}
.direction_badge {
  letter-spacing: 0.25px;
  font-size: 10px;
  padding: 2px 4px;
  width: max-content;
  min-width: 18px;
  text-transform: capitalize;
  border-radius: 4px;
  line-height: 14px;
}
.direction_badge_wrap .bg_green.per_change {
  color: #2bbf89;
  background-color: rgba(43, 191, 137, 0.18) !important;
}
.direction_badge_long {
  color: #2bbf89;
  background-color: rgba(43, 191, 137, 0.18);
}
.direction_badge_short {
  color: #f96c5a;
  background-color: rgba(249, 108, 90, 0.23);
}
.bot_preview_box_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.bot_preview_title {
  color: #a2a7b9;
  font-size: 12px;
  line-height: 16px;
}
.bot_preview_box {
  background-color: #2b273b;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 12px;
}
.bot_preview_box > div {
  display: flex;
  column-gap: 4px;
  font-size: 12px;
  position: relative;
}
.border_line {
  position: relative;
  flex-grow: 1;
  opacity: 0.2;
}
.border_line::before {
  content: "";
  border-bottom: 1px dashed #818c99;
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: calc(100% - 8px);
  height: 1px;
  transform: translate(-50%, 50%);
}
.bot_preview_box > div span:nth-child(1) {
  color: #a2a7b9;
}
.back_btn {
  min-width: 48px;
  padding: 5px;
  color: #0078ff;
  border-radius: 8px;
  background-color: #323545;
  border: 1px solid #323545;
}
.back_btn:hover {
  background-color: #404457;
  border: 1px solid #404457;
}
.back_btn i::before {
  font-size: 36px;
}
.bot_preview_btngrp {
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-top: 16px;
}
.column_btngrp {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 15px;
}
.bot_actions_card .offcanvas {
  position: absolute;
  top: unset;
  color: #fff;
  background: #221f2f;
  border-radius: 8px;
  height: auto;
}
.bot_actions_card h5.offcanvas-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.bot_actions_card .offcanvas-header .btn-close {
  filter: invert(1);
  background-size: 30%;
  box-shadow: none !important;
}
.bot_actions_card .offcanvas-body > div {
  background-color: #2b273b;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: 0.2s all;
}
.bot_actions_card .offcanvas-body > div h6 {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
}
.bot_actions_card .offcanvas-body > div p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #a2a7b9;
  margin-bottom: 0;
}
.bot_actions_card .offcanvas-body > div:hover {
  background-color: #312b45;
}
.rdt_TableCell button.primary_btn.primary_black_btn {
  min-height: auto;
  padding: 5px;
  font-size: 12px;
}
.bot_profit {
  display: flex;
  padding: 10px 18px 15px;
  gap: 30px;
}
.bot_profit p {
  margin-bottom: 0;
}
.bot_profit p span {
  color: #a2a7b9;
  font-size: 13px;
  line-height: 20px;
}
.create_bot_img_wrap {
  padding: 30px 10px;
  text-align: center;
}
.create_bot_img {
  background-color: #2c273d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 15px auto;
  border-radius: 50%;
}
.create_bot_img_wrap .progress {
  height: 5px;
  background: #2c273d;
}
.create_bot_img_wrap .progress-bar {
  background: #2bbf89;
}
.create_bot_img_wrap h5 {
  letter-spacing: .25px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}
.create_bot_img_wrap p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #a2a7b9;
}



/* Light Theme Css Starts */
body.light_theme {
  background: #e5e5e6;
}
.light_theme .crediantial-form {
  background: #fff;
}
.light_theme .crediantial-form h2 {
  color: #008ccc;
}
.light_theme .crediantial-form h6,
.light_theme .form-group label,
.light_theme .crediantila-bottom {
  color: #333;
}
.light_theme .form-control,
.light_theme .form-select,
.light_theme .input-group-text.select-input-group,
.light_theme .react-tel-input .form-control,
.light_theme .react-tel-input .flag-dropdown,
.light_theme .react-tel-input .flag-dropdown.open,
.light_theme .react-tel-input .flag-dropdown.open .selected-flag,
.light_theme .react-tel-input .selected-flag:hover,
.light_theme .react-tel-input .selected-flag:focus {
  background-color: #e5e5e6 !important;
}
.light_theme .crediantial-form .nav-tabs .nav-link {
  background: #e5e5e6;
  color: #7b7b7b;
}
.light_theme .crediantial-form .nav-tabs .nav-link:hover,
.light_theme .crediantial-form .nav-tabs .nav-link.active {
  background: #f0df72;
  color: #ffffff;
}
.light_theme .profileSideMenu,
.light_theme .userContent,
.light_theme nav.navbar.userTopMenu.trade_menu,
.light_theme .dashboardContent,
.light_theme .trade_topbar,
.light_theme .trade_card .card-body,
.light_theme .trade_options_card,
.light_theme .strategy_settings_panel {
  background: #fff;
}
.light_theme .profileSideMenu .navbar-nav .nav-item a,
.light_theme nav.trade_menu ul.topProfileDropdown li a {
  color: #333;
}
.light_theme .profileSideMenuBottom a.nav-link.active {
  color: #f0df72;
}
.light_theme button.navbar-toggler {
  border: 0;
}
.light_theme img.share_icon {
  filter: invert(1);
}
.light_theme .hamburger .bar {
  background-color: #333;
}
.light_theme .profileSideMenuTop h3,
.light_theme .userContent h2,
.light_theme .pair_table_bodyrow_top > div:first-child h3,
.light_theme .chartTitle h4,
.light_theme .dashboardContentTitleBar h3,
.light_theme .dashboardTabs li button.active,
.light_theme .trade_topbar h3 {
  color: #111 !important;
}
.light_theme .profileSideMenuTop p a,
.light_theme .profileSideMenuTop p small {
  color: #404040;
}
.light_theme .dashboardContentTitleBar,
.light_theme .trade_card .card-header,
.light_theme .trade_topbar > div:first-child {
  background-color: #f3f3f3;
}
.light_theme .primary_table .rdt_TableHeadRow,
.light_theme .primary_table .rdt_Pagination,
.light_theme .primary_table .rdt_TableRow {
  background-color: #fff !important;
  color: #222 !important;
}
.light_theme .greenText {
  color: #f0df72 !important;
}
.light_theme .trade_topbar h3.greenText {
  color: #f0df72 !important;
}
.light_theme .trade_topbar h3.redText {
  color: #ff3636 !important;
}
.light_theme .pair_table_bodyrow_bottom_left > div label:nth-child(2),
.light_theme .pair_table_bodyrow_bottom_right > div label:nth-child(2),
.light_theme label {
  color: #333;
}
.light_theme .rdt_Table > div {
  color: #333 !important;
  background-color: #fff !important;
}
.light_theme .redText {
  color: #ff3636 !important;
}
.light_theme .pair_table_bodyrow_bottom label,
.light_theme .pair_table_bodyrow_top > div:first-child h3 span {
  color: #525475;
}
.light_theme .pair_table_bodyrow.active,
.light_theme .pair_table_bodyrow:hover {
  background: rgb(229 229 230 / 50%);
}
.light_theme .chartTitle .form-select {
  background-color: #e5e5e6;
}
.light_theme .chartTitle {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .primary_table .rdt_TableHeadRow,
.light_theme .profileSideMenuTop {
  border-bottom: 1px solid #e7e7e7 !important;
}
.light_theme .primary_table .rdt_Pagination {
  border-top: 1px solid #e7e7e7 !important;
}
.light_theme .userFooter {
  background: #ffffff;
}
.light_theme .userFooter p {
  color: #111;
}
.light_theme .slick-prev:before,
.light_theme .slick-next:before {
  color: #111;
}
.light_theme .pairCard .pair_table_bodyrow {
  border: 1px solid #e7e7e7;
}
.light_theme .dashboardBalanceDetails h2 {
  color: #5d5d5d;
}
.light_theme .theme_button.menu_round_btn,
.light_theme .menu_round_btn {
  background: #e5e5e6;
}
.light_theme .new_bot_btn:hover {
  color: #008ccc;
}
.light_theme .profile_dropdown li a img {
  filter: brightness(0) invert(1) contrast(0);
}
.light_theme .dropdown-menu {
  background: #f3f3f3;
  border-color: #f2f2f2;
}
.light_theme .profile_dropdown li {
  border-bottom: 1px solid #e5e5e6;
}
.light_theme .profile_dropdown::after {
  border-bottom: 15px solid #f3f3f3;
}
.light_theme .profile_dropdown .dropdown-item.active,
.light_theme .profile_dropdown .dropdown-item:active,
.light_theme .profile_dropdown .dropdown-item:focus,
.light_theme .profile_dropdown .dropdown-item:hover {
  background-color: #d6d6d6 !important;
}
.light_theme .form-card {
  border: 2px solid #e7e7e7;
}
.light_theme .form-card h3 span {
  background: #fff;
  color: #111;
}
.light_theme .noteList li {
  color: #5d5d5d;
}
.light_theme .primary_btn:hover {
  color: #f0df72;
}
.light_theme button.primary_btn.btn-danger:hover {
  color: #dc3545;
}
.light_theme .member_box {
  background-color: #e5e5e6;
}
.light_theme .member_box > div:nth-child(1) h4,
.light_theme .member_box p {
  color: #111;
}
.light_theme .member_box h5 {
  color: #555;
}
.light_theme .dashboardPairDetails p {
  color: #333;
}
.light_theme .contact_sec a,
.light_theme .contact_sec p {
  color: #333;
}
.light_theme .contact_sec p span {
  color: #7f7f7f;
}
.light_theme .revenue_flex,
.light_theme .tfa_status {
  background: #e5e5e6;
}
.light_theme .revenue_flex h4,
.light_theme .notification_panel h4,
.light_theme .api_binding_card_title h3,
.light_theme .referral_panel h3,
.light_theme .trade_card .card-header h2 {
  color: #111;
}
.light_theme .tfa_status p,
.light_theme .notes_section h5,
.light_theme .notes_section p,
.light_theme .notes_section ul li,
.light_theme .api_binding_card_content ol li,
.light_theme .api_binding_card_content p {
  color: #333;
}
.light_theme .walletSideMenuBottom .nav-item .nav-link {
  background: #d6d6d6;
  color: #111;
}
.light_theme .message_icon {
  filter: brightness(0.45);
}
.light_theme .walletSideMenuBottom .nav-item .nav-link.active,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover {
  background: #008ccc;
  color: #fff;
}
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .message_icon,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .message_icon {
  filter: unset;
}
.light_theme .notification_panel div:nth-child(1),
.light_theme .WalletBalance,
.light_theme .walletForm,
.light_theme .greenNote {
  border-top: 1px solid #e7e7e7;
}
.light_theme .notification_panel div {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .api_binding_tab .nav-item .nav-link {
  background: #f3f3f3;
  border: 1px solid #e7e7e7;
}
.light_theme .api_binding_tab .nav-item .nav-link.active {
  background: linear-gradient(
    100deg,
    rgba(0, 96, 170, 1) 0%,
    rgba(0, 152, 70, 1) 100%
  );
}
.light_theme .api_binding_card {
  background: #f3f3f3;
}
.light_theme .api_binding_card_title {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .referral_panel {
  border: 2px solid #e7e7e7;
}
.light_theme .invite_btn img {
  filter: brightness(0);
}
.light_theme .primary_modal .modal-content {
  background: #fff;
}
.light_theme .primary_modal .modal-header {
  border-bottom: none;
  color: #111;
}
.light_theme .primary_modal .btn-close {
  filter: brightness(0.5);
}
.light_theme .trade_topbar p {
  color: #6f6f6f;
}
.light_theme .trade_card .card-body,
.light_theme .trade_options_card div h3,
.light_theme .strategy_settings_div h5 {
  color: #333;
}
.light_theme .strategy_settings_div h6 {
  color: #40414f;
}
.light_theme .trade_card ul.nav-tabs,
.light_theme .batch_settings {
  background: #f3f3f3;
}
.light_theme .trade_card .nav-tabs .nav-item.show .nav-link,
.light_theme .trade_card .nav-tabs .nav-link.active,
.light_theme .trade_card .nav-tabs .nav-link:focus,
.light_theme .trade_card .nav-tabs .nav-link:hover {
  background: #008ccc;
}
.light_theme input.form-control.search_inp {
  border: 1px solid #e7e7e7 !important;
  color: #333;
}
.light_theme .pair_table_head > div {
  color: #333;
}
.light_theme .pair_table_head,
.light_theme .pair_table_bodyrow {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .trade_history_card .nav-tabs .nav-item.show .nav-link,
.light_theme .trade_history_card .nav-tabs .nav-link.active,
.light_theme .trade_history_card .nav-tabs .nav-link:focus,
.light_theme .trade_history_card .nav-tabs .nav-link:hover {
  background: transparent;
  border-bottom: 2px solid #008ccc;
}
.light_theme .trade_history_card .nav-tabs .nav-link {
  color: #111;
}
.light_theme .batch_settings p {
  color: #111;
}
.light_theme .trade_options_card div:nth-child(1),
.light_theme .trade_options_card div:nth-child(3) {
  border-right: 1px solid #e7e7e7;
}
.light_theme .trade_options_card div:nth-child(1),
.light_theme .trade_options_card div:nth-child(2) {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .strategy_settings_div {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .track_vertical {
  background-color: #e1e1e1;
}
.light_theme .thumb_vertical {
  background-color: #9d9d9d;
}
.light_theme .batch_settings_btn_grp button {
  background: #e5e5e6;
  border: 1px solid #e7e7e7;
  color: #333;
}
.light_theme .batch_settings_modal_panel .coin_details {
  border: 1px solid #e7e7e7;
}
.light_theme .operate_settings h3,
.light_theme .operate_settings label {
  color: #333;
}
.light_theme .strategy_btn:hover {
  background: transparent;
  color: #008ccc;
}
.light_theme .new_strategy_settings_panel > div {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme button.primary_btn.primary_blue_btn:hover {
  color: #008ccc;
}
.light_theme .walletMenuIcon1,
.light_theme .walletMenuIcon2,
.light_theme .walletMenuIcon3,
.light_theme .walletMenuIcon4 {
  filter: brightness(0.45);
}
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .walletMenuIcon1,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .walletMenuIcon1,
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .walletMenuIcon2,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .walletMenuIcon2,
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .walletMenuIcon3,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .walletMenuIcon3,
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .walletMenuIcon4,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .walletMenuIcon4 {
  filter: unset;
}
.light_theme .customTable {
  border-color: #e7e7e7;
}
.light_theme .customTable td {
  color: #222222;
}
.light_theme .wbCard h6,
.light_theme .customTable th {
  color: #1f1f1f;
}
.light_theme .customTable .edit_btn i {
  color: #008ccc;
}
.light_theme .customTable .edit_btn:hover i {
  color: #222222;
}
.light_theme .withdrawDetailBox ul li {
  color: #333;
}
.light_theme h2.kyc_success {
  color: #f0df72 !important;
}
.light_theme .modal-body {
  color: #333 !important;
}
/* Light Theme Css Ends */


.drpdwn_box .dropdown{
  width: 300px;
}
.drpdwn_box button{
  color: #fff;
  border: 1px solid #1d1a28 !important;
  background-color: #1d1a28 !important;
  font-size: 14px; 
  border-radius: 8px;
  text-align: left;
  position: relative;
  width: 100%;
  box-shadow: none !important;
  font-weight: 500;
}
.drpdwn_box button:hover{
  border-color: #333545 !important;
}
.drpdwn_box button::after{
  position: absolute;
  right: 15px;
  color: #5f6171;
}
.drpdwn_box button+div{
  width: 100%;
  background: #1d1a28;
}
.drpdwn_box .dropdown-menu a{
  color: #fff;
  font-size: 13px;
  padding: 8px;
}
.drpdwn_box .dropdown-menu a:hover,
.drpdwn_box .dropdown-menu a:focus{
  background-color: rgb(49 45 63) !important;
}
.drpdwn_box button .exchange{
  color: #a2a7b9;
  font-size: 10px;
  display: block;
}
.drpdwn_box button#dropdown-basic img {
  width: 20px;
}


.inputgrp_box .input-group{
  border-radius: 8px;
  width: 300px;
  overflow: hidden;
  border: 1px solid transparent;
}
.inputgrp_box .input-group:hover{
  border: 1px solid #333545;
}
.inputgrp_box .input-group-text{
  background: #1d1a28;
  min-width: unset;
  padding: 12px;
  font-size: 12px !important;
}
.inputgrp_box input{
  background: #1d1a28;
  font-size: 14px; 
  color: #fff;
  padding: 14px !important;
  font-weight: 500;
}
.inputgrp_box input:focus{
  background: #1d1a28;
  color: #fff;
}
.inputgrp_box input::placeholder{
  color: #a2a7b9;
} 



.floating_input input{
  background: #1d1a28 !important;
  border: 1px solid #1d1a28 !important;
  color: #fff !important;
  font-size: 14px;
  padding-bottom: 0 !important;
  height: calc(3.1rem + 2px) !important;
}
.floating_input input:hover{
  border-color: #333545 !important;
}
.floating_input label{
  color: #a2a7b9;
  font-size: 13px;
}
.floating_input input:focus label{
  font-size: 11px; 
  font-weight: 400;
}

.floating_input_chg input{
  color: #a2a7b9 !important; 
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 45px !important;

}
.floating_input_chg .input_text{
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  background: #1d1a28;
  width: 50px;
  text-align: center;
}
.floating_input_chg .input_text svg{
  fill: #a2a7b9;

}

.head_h2 {
  font-size: 24px;
  font-weight: 700;
}
.port_folio .h_flx{
  display: flex;
  align-items: center;
  grid-column-gap: 51px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(79,79,85,.5);
}
.port_folio .h_flx p{
  opacity: .5;
  padding-bottom: 15px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.port_folio .h_flx p.active{
  opacity: 1;
  border-bottom: 1px solid #0078ff;
}
.port_folio .info_box{
  background-color: #231f30;
  display: grid;
  width: 100%;
  padding: 18px 20px;
  font-weight: 500;
  grid-gap: 57px;
  grid-template-columns: 1.7fr repeat(4,1fr);
}
.port_folio .info_box h6{
  font-size: 24px;
  margin-bottom: 0;
}
.port_folio .info_box span{
  margin-bottom: 5px;
  font-size: 13px;
  color: rgba(255,255,255,.5);
}
.port_folio .info_box p{
  margin-bottom: 0;
  font-size: 15px;
}
.port_folio .info_box .money{
  color: rgba(255,255,255,.7);
  margin-top: 2px;
  font-size: 13px;
}
.port_folio .info_box .red{
  color: #f96c5a;
}
.port_folio .info_box .green{
  color: #2bbf89;
}

.port_folio .rdt_TableHeadRow{
  background-color: #161617;
}
.port_folio .rdt_TableRow{
  height: 54px;
}
.port_folio .rdt_TableRow:nth-child(odd){
 background-color: #292636;
}
.port_folio .rdt_TableRow:nth-child(even){
  background-color: #231f30;
 }
.port_folio .rdt_TableCell{
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
.port_folio .rdt_TableCell p{
  margin-bottom: 0;
}
.port_folio .rdt_TableCell span{
  font-size: 13px;
  color: rgba(255,255,255,.7);
}
.port_folio .coin{
  display: flex;
  align-items: center;
  gap: 10px;
}
.port_folio .crypto{
  height: 20px;
}
.port_folio .table_sec .red{
  color: #f96c5a;
}
.port_folio .table_sec .green{
  color: #2bbf89;
}
.port_folio .rdt_TableCol{
  font-size: 13px;
  color: rgba(255,255,255,.7);
}
.port_folio .rdt_TableCol >div{
  align-items: flex-end;
  padding-bottom: 5px;
}


.port_folio .table_sec_chg  .rdt_TableHeadRow{
  background-color: #231f30;
}
.port_folio .table_sec_chg .rdt_TableCol >div {
 align-items:center ;
 padding-bottom: 0;
}
.port_folio .table_sec_chg span{
  font-size: 15px;
  color: #a2a7b9;
}

.port_folio .abv_flx{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.export_btn{
  border: 1px solid #333545;
  background-color: #231f30;
  padding: 13px 20px!important;
  margin: 0;
  font-size: 14px;
  border-radius: 8px;
  color: #a2a7b9;
  width: fit-content;
}
.export_btn:hover{
  color: #fff;
  background-color: #231f30;
}
.export_btn.locked{
  color: #888d9d;
    border-color: #404457;
    background-color: #404457;
    cursor: default;
    display: flex;
    align-items: center;
}


.inputgrp_box .search{
  height: 18px;
}



.my_exchange .head_flx{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my_exchange .head_flx p{
  color: #0078ff;
  cursor: pointer;
  font-size: 17px;
}
.my_exchange .ex_card{ 
  color: #fff;
  background-color: #231f30;
}
.my_exchange .abv{
  display: flex;
  padding: 17.5px 16px;
  align-items: center;
 }
 .my_exchange .abv img{
  margin-right: 14px;
 }
 .my_exchange .abv p{
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 0;
 }
 .my_exchange .balance{
  background-color: #1c1c24;
  display: flex;
    justify-content: space-between;
    padding: 12px 14px;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
 }
 .my_exchange .blw_flx{
  display: flex;
    justify-content: space-between;
    padding: 11px 15px;
    font-size: 15px;
    line-height: 100%;
    color: #0078ff;
 }
 .my_exchange .blw_flx span{
  cursor: pointer;
 }


 .custom_modal {
  padding: 0 !important;
 }
 .custom_modal .modal-header{
  border: 0;
 }
 .custom_modal .modal-dialog{
  justify-content: center;
  align-items: center;
  display: flex;
 }
 .custom_modal .modal-content{
  background-color: #231f30;
  width: 400px;
 }
 .custom_modal .drpdwn_box .dropdown{
  width: 100%;
 }
 .custom_modal .atag{
  cursor: pointer;
  text-decoration: none;
  color: #0078ff; 
  font-size: 13px;
 }
.custom_modal .para{
  color: #a2a7b9;
  font-size: 13px;
}
.custom_modal .modal-title{ 
    margin: 0 auto;
    text-align: center;
    letter-spacing: .25px;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
}
.custom_modal .modal-body{
  padding-top: 0;
}
.custom_modal .btn-close {
  background: url("../images/close.png") no-repeat center;
  position: absolute;
  right: 20px;
  box-shadow: none !important;
}
.custom_modal .blue_btn{
  color: #fff;
  border-color: #0078ff;
  background-color: #0078ff;
  min-height: 48px;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 16px;
}

.no_data {
  text-align: center;
  color: #a2a7b9;
  font-size: 12px;
  font-weight: 500;
  margin: 0 10px;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.add_exchange{
  padding: 10px !important;
  font-size: 13px;
 }
.small_icon{
  height: 20px;
}
.leftside_menu {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  gap: 20px;
  position: relative;
  padding: 0;
}
.menubar_left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.userTopMenu .nav-link.active {
  color: #f4e478;
}
.bind_btn_grp {
  display: flex;
  align-items: center;
  gap: 15px;
}
.ex_type {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  padding: 5px;
}
.tablebot_status {
  background-color: rgba(255,255,255,.1);
  color: rgba(255,255,255,.7);
  padding: 2px 4px;
  letter-spacing: .25px;
  font-size: 10px;
  width: max-content;
  min-width: 18px;
  border-radius: 4px;
  line-height: 14px;
  text-transform: uppercase;
}
.table_pairtype {
  display: flex;
  align-items: center;
  gap: 5px;
}
.table_pairtype p {
  padding: 2px 4px;
  color: #fff;
  letter-spacing: .25px;
  font-size: 10px;
  width: max-content;
  min-width: 18px;
  border-radius: 4px;
  line-height: 14px;
  text-transform: capitalize;
}
.table_pairtype p:first-child {
  background-color: #191426;
}
.table_pairtype p:last-child {
  color: #2bbf89;
  background-color: rgba(43,191,137,.18);
}
.rdt_TableCell {
  font-size: 14px;
}
.rdt_TableCol {
  color: #a2a7b9;
  font-size: 13px;
}
.primary_table .rdt_TableRow:nth-child(even) {
  background-color: #231f30 ;
}
.primary_table .rdt_TableRow:hover {
  background-color: #2a3457 !important; 
}
.primary_table .rdt_TableRow:hover::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 4px;
  height: 100%;
  border-radius: 0 8px 8px 0;
  background-color: #0078ff;
}
.primary_table .selected-row-active {
  background-color: #2a3457 !important; 
}
.primary_table .selected-row-active::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 4px;
  height: 100%;
  border-radius: 0 8px 8px 0;
  background-color: #0078ff;
}
.primary_table_normal thead th {
  color: #a2a7b9;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: .25px;
  font-weight: 400;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: middle;
}
.primary_table_normal tbody td {
  vertical-align: middle;
  letter-spacing: .25px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  border: none;
}
.primary_table_normal tbody tr:nth-child(odd) {
  background-color: #2c273d;
}
.primary_table_normal tbody td .usd_eqiv {
  font-size: 12px;
}
.bal_pair {
  display: flex;
  align-items: center;
  gap: 3px;
}
.bal_pair span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: .25px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
}
.primary_table_normal tfoot tr {
  background-color: #2c273d;
}
.primary_table_normal tfoot td {
  font-size: 12px;
  letter-spacing: .25px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  border: none;
  vertical-align: top;
  word-break: break-all;
}
.filter_panel_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 24px;
}
.filter_panel_btn .border_btn {
  color: #a2a7b9;
  border: 1px solid #333545;
  background-color: #231f30;
}
.chart_custom_data {
  position: absolute;
  left: 200px;
  top: 14px;
  display: flex;
  align-items: center;
}
.chart_custom_data p {
  color: #d1d4dc;
  font-size: 14px;
  margin-bottom: 0;
  cursor: default;
  user-select: none;
}
span.seperator {
  border-left: 1px solid #434651;
  width: 0;
  height: 22px;
  margin: 0 10px;
}

span.seperatorf {
  width: 0;
  height: 22px;
  margin: 0 10px;
}
.custom_tooltip ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.custom_tooltip ul li {
  margin-bottom: 8px;
}
.custom_tooltip ul li small {
  background-color: rgba(255,255,255,.1);
  color: rgba(255,255,255,.7);
  padding: 2px 4px;
  letter-spacing: .25px;
  font-size: 10px;
  width: max-content;
  min-width: 18px;
  border-radius: 4px;
  line-height: 14px;
  text-transform: uppercase;
}
.drpdwn_box .dropdown-menu img {
  width: 20px;
}
.pair_dropdown {
  border: 1px solid #1d1a28 !important;
  background-color: #1d1a28 !important;
  border-radius: 8px;
  text-align: left;
  position: relative;
  width: 100%;
  box-shadow: none !important;
  height: 50px;
  padding: 8px;
}
.pair_dropdown h2 { 
  color: #a2a7b9;
  font-size: 10px;
  margin-bottom: 3px;
}
.pair_dropdown p {
  font-size: 14px;
  font-weight: 500;
}
.accr_box .box span {
  font-size: 10px;
}
.accr_box .box span img {
  width: 20px;
}
.drp_dn   .dropdown-toggle{
  padding: 0;
  border: 0;
  box-shadow:  none !important;
  background: transparent !important;
  width: 100%;
}
.drp_dn  .dropdown-toggle::after{
  display: none;
}
.drp_dn   .dropdown-menu{
  width: 100%;
  padding: 0;
}
.drp_dn .pair_table_bodyrow_top{
  color: #fff;
}
.make_drpdwn{
    border: 1px solid #333545;
    background: #1d1a28;
    border-radius: 0.375rem; 
    width: 100%; 
}
.zindex{
  z-index: 1;
}
.createbot_box .t_box{
  color: #fff; 
  background-color: #0078ff;
  min-height: 30px;
  padding: 7px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}
.createbot_box .grey{
  color: #a2a7b9;
  font-size: 10px;
}
.createbot_box .inputgrp_box{
  position: relative;
}
.createbot_box .inputgrp_box label{
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 10;
  margin-bottom: 0;
}
.createbot_box .inputgrp_box input{
  height: auto;
    padding: 22px 11px 6px !important;
}
.accr_box .accordion-item{
  border: 0;
  background: transparent;
}
.accr_box .accordion-header{
  background: transparent;
  padding: 0;
  border: 0;
}
.accr_box .accordion-button{
  border: 0px  !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  background: #231f30 !important;
  min-height: unset;
  padding: 0;
  color: #a2a7b9;
  box-shadow: none !important;
}
.accr_box .center .accordion-button{
  justify-content: center;
}
.accr_box .accordion-button:hover,
.accr_box [aria-expanded="true"].accordion-button{
  color: #fff;
}
.accr_box .accordion-button::after{
  display: none;
}
.accr_box .accordion-button div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.accr_box .blue{
  color: #0078ff;
  justify-content: center !important;
  width: auto !important;
}
.accr_box .accordion-button div::after{
  background: url("../images/down_arw.png") no-repeat center !important;
  border: 0 !important;
  background-color: transparent !important;
  content: "";
  font-size: 0;
  width: 9px;
  background-size: contain !important;
  display: block;
  height: 9px;  
  transform: rotate(-90deg);
  transition: .3s;
}
.accr_box .accordion-button div:not(.blue):after{
  filter: grayscale(100%) invert(4) brightness(20.5);
  width: 7px;
}
.accr_box .accordion-button[aria-expanded="true"] div::after{
  transform: rotate(0);
}
.accr_box .accordion-body{
  color: #fff;
  padding-left: 0;
  padding-right: 0;
}
.accr_box .box{
  background: #2b273b;
  display: flex;
  flex-direction: column;
  padding: 12px 10px;
  border-radius: 8px;
  row-gap: 12px;
  margin-bottom: 8px;
}
.accr_box .box .box_head{
  font-size: 12px;
    font-weight: 500;
}
.accr_box .box .tab{
   display: flex;
   gap: 10px;
   align-items: center;
}
.accr_box .box .tab > div{
  width: 50%;
  position: relative;
  padding: 0;
  margin: 0;
}
.accr_box .box .tab input{
  border:1px solid #333545;
  background-color: rgba(0, 119, 255, 0);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  box-shadow: none !important;
  width: 100%;
  margin: 0;
  background-image: none;
  cursor: pointer;
  z-index: 1;
  position: relative;
}
.accr_box .box .tab input:checked{
  border:1px solid #0078ff;
  background-color: rgba(0,120,255,.2);
  box-shadow: none !important;
}
.accr_box .box .tab label{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  font-weight: 500;
  z-index: 0; 
}
.accr_box .box .center label{
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}
.accr_box .box .center input{
  text-align: center;
}
.cursor{
  cursor: pointer;
}
.accr_box .box .chk_box .form-control{
  background-color: #1d1a28 !important;
}
.accr_box .box .value{
  display: flex;
  align-items: flex-end;
  -moz-column-gap: 4px;
  column-gap: 4px;
  text-align: right; 
  color: #a2a7b9;
  font-size: 12px;
}
.accr_box .box .value div:nth-child(1){
  color: #a2a7b9;
  display: flex;
  max-width: 70%;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  gap: 5px;
}
.accr_box .box .value .bdr{
  position: relative;
  flex-grow: 1;
  opacity: .2;
  color: #fff;
}
.accr_box .box .value .bdr::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: calc(100% - 8px);
  height: 1px;
  transform: translate(-50%,50%);
  border-bottom: 1px dashed #818c99;
}
.accr_box .green{
  color: #2bbf89;
}
.continue {
  color: #fff;
  border:0;
  background-color: #0078ff;
  min-height: 48px;
  padding: 8px 20px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  font-size: 14px; 
  border-radius: 8px;
}
.backtest{
  color: #fff;
  border:0;
  background-color: #323545;
  min-height: 48px;
  padding: 8px 20px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  font-size: 14px; 
  border-radius: 8px;
}
.bgcolor{
  border-top: 1px solid #2b273b;
  background-color: rgba(34,31,47,.9);
  padding: 15px;
  padding-bottom: 30px;
  border-radius: 0;
  backdrop-filter: blur(3.5px);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.cardheadbg .card-header{
  background-color: #231f30;
  border: 0;
}
/* .scroll{
  height: 345px;
  overflow-y: scroll;
} */
/* .trade_card ::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}
.trade_card ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #231f30;
  background-color: #231f30;
}
.trade_card ::-webkit-scrollbar-thumb {
  background-color: #0078ff;
  border-radius: 10px;
} */

.slider_box input{
  width: 100%;
  height: 4px; 
}

.slider_box .MuiSlider-rail{
  background-color: #43404e;
}
.slider_box .MuiSlider-track{
  background-color: #0078ff;
}
.slider_box .MuiSlider-thumb {
  background-color: #fff;
  box-shadow: none !important;
  border: 0;
}
.slider_box .MuiSlider-valueLabel{
  background-color: transparent;
  font-size: 12px;
  padding: 0;
  font-weight: 500;
  transform: translateY(-70%) scale(1) !important;
}

.chk_box .form-check {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.chk_box input{
  height: 24px;
  min-width: 42px;
  background-color: #4f4f55;
  box-shadow: none !important;
  background-image: black;
  border: 0;
}
.chk_box input:checked{
  background-color: #2e79f6;
}
.bottab {
  border: 1px solid #333545 !important ;
  background: #221f2f !important;
  border-radius: 8px;
  padding: 3px !important;
  margin: 0 10px;
  gap: 1px;
}
.bottab .active{
  background: #333545 !important;
}
.bottab button.nav-link:hover{
  color: #fff;
}
.react-tooltip__show{
  opacity: 1 !important;
  z-index: 20000 !important;
}
.react-tooltip {
  z-index: 100; 
  border: 1px solid #333545 !important;
  background-color: #2b273b !important;
  color: #a2a7b9 !important; 
  width: 360px !important;
}
.bi-info-circle-fill{
  color: #565861;
}
.font{
  font-size: 12px;
}
.white{
  color: #fff !important;
}
.blue_bage{
  background-color: #0078ff;
  padding: 2px 4px;
  color: #fff;
  border-radius: 4px;
  font-size: 10px;
}


.tab_btn {
  display: flex;
  height: 40px;
  padding: 3px;
  border: 1px solid;
  border-radius: 8px;
  border-color: #333545;
  background-color: #221f2f;
  font-size: 12px;
  font-weight: 500; 
}
.tab_btn button{
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #fff;
  width: 50%;
  height: 32px;
  min-height: auto;
  padding: 9px 12px;
}
.tab_btn button:hover{
  color: #fff;
}
.tab_btn .active{
  background-color: #333545;
  min-width: auto;
  height: 32px;
  min-height: auto;
  padding: 9px 12px;
  color: #fff;
  border-radius: 8px;
}
.right_tab{
  border: 0;
  width: fit-content;
  white-space: nowrap;
  margin-left: auto;
}
.pair_flx {
  display: flex;
}
.botdetail_height{
  height: 700px;
  overflow-y: scroll;
}
.botdetail_height_chg{
  height: 340px;
}
.dark_bage{
  background-color: #191426;
  padding: 2px 4px;
  color: #fff;
  letter-spacing: .25px;
  font-size: 10px;
  text-transform: capitalize;
  border-radius: 4px;
}
.light_bage{
  background-color: rgba(255,255,255,.1);
  padding: 2px 4px;
  color: #fff;
  letter-spacing: .25px;
  font-size: 10px;
  text-transform: capitalize;
  border-radius: 4px;
}
.flx_box {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
}
.flx_box .flx{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flx_box .box{
  background: #2b273b;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  row-gap: 8px;
}
.flx_box .box span{
  font-size: 12px;
  color: #a2a7b9;
}
.flx_box .box h6{
  font-size: 16px;
 color: #fff;
 margin-bottom: 0;
}
.flx_box .box p{
  font-size: 18px;
  margin-bottom: 0;
}
.half_view{
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 16px;
  width: 150px;
  white-space: nowrap;
}
.scroll_drp{
  height: 210px;
  overflow-y: scroll;
}
.orange_link{
  color: #af7708;
}
.info_btn{
  background-color: #3c4b87 !important;
  border-color: #273051 !important;
}

.filter_flx{
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}
.filter_flx .icon{
  border-radius: 0 8px 8px 0;
  padding: 5px;
  padding-top: 13px;
  background: #14121d;
  margin-left: -8px;
}
.filter_flx input{
  border: 1px solid #343546;
  padding: 9px;
  border-radius: 5px;
  color: #fff;
  box-shadow: none !important;
  outline: 0 !important;
}
.filter_flx .continue{
  min-height: 38px;
  margin-bottom: 2px;
}
.filter_flx .react-datepicker__header{
  border-bottom: 1px solid #343546;
  background-color: #2c273d;
}
.filter_flx .react-datepicker__month-container,
.filter_flx .react-datepicker__time-list{
    background: #231f30;
    color: #fff;
}
.filter_flx .react-datepicker{
  border: 1px solid #343547;
  color: #fff;
}
.filter_flx .react-datepicker__day--keyboard-selected{
  background-color: #216ba5;
}
.filter_flx .react-datepicker__day,
.filter_flx .react-datepicker__day-name,
.filter_flx .react-datepicker__current-month,
.filter_flx .react-datepicker-time__header{
  color: #fff;
}

.filter_flx .react-datepicker__day:hover,
.filter_flx .react-datepicker__time-list-item:hover{
  background-color: #236ca5 !important;
}
.filter_flx .react-datepicker__time-container{
  border-left: 1px solid #343648;
}
.stopbot{
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}
.stopbot .form-check{
  background: #2c273d;
  border-radius: 8px;
  padding: 11px 12px;
  border: 1px solid rgba(129,140,153,.2);
  position: relative;
  /* height: 40px;
  min-height: 40px; */
  overflow: hidden;
}
.stopbot .form-check.active{
  border: 1px solid rgb(17 119 246);
}
.stopbot .form-check p{
  margin-top: 12px;
  color: #a2a7b9;
  font-size: 13px;
  font-weight: 500;
}
.stopbot .primary_btn{
  font-size: 14px;
}
.stopbot .cancel{
  background-color: transparent !important;

  color: #a2a7b9;
  border: 1px solid #333545 !important;
}
.stopbot label{
  /* position: absolute;
  width: 100%;
  height: 100%;
  left: 32px;
  left: 20px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer; */
}
.pair_table_chg .pair_table_head{
  padding-left: 20px;
  padding-right: 0;
}
.pair_table_chg .pair_table_head > div:first-child{
  width: 140px;
}
.pair_table_chg .pair_table_bodyrow_top{
  width: 140px;
}

/* New Css 18-12-2023 */
.bot_plan_details {
  display: flex;
  align-items: center;
  margin-right: 8px;
  gap: 12px;
  background: #2c283d;
  padding: 8px;
}
.running_bots_count {
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
}
.running_bots_count svg {
  width: 16px;
  height: 16px;
  color: #00cf5c;
}
.botstatus_dropdown {
  width: 300px;
  padding: 20px 16px;
  border-radius: 8px;
}
.botstatus_dropdown h3 {
  padding-bottom: 16px;
  text-align: center;
  letter-spacing: .25px;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  margin: 0;
}
.botstatus_dropdown p {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #a2a7b9;
  margin: 15px 0;
}
.flex_btns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.trade_main_wrapper {
  display: grid;
  justify-content: center;
  height: 100%;
  grid-gap: 4px;
  grid-template-areas:
      "chart column"
      "bots column";
  grid-template-columns: 1fr 300px;
  grid-template-rows: 540px -webkit-min-content;
  grid-template-rows: 540px min-content;
}
.primary_table_full_height {
  height: 706px;
}
.drpdwn_box {
  margin-bottom: 8px;
}
.createbot_card {
  max-height: 680px;
  overflow: auto;
  padding-bottom: 160px !important;
  border-radius: 8px;
}
.createbot_card .coin_details {
  height: auto;
  width: auto;
}
.createbot_box {
  margin: 8px 0;
}
.pair_uparrow svg {
  transform: rotate(-90deg);
  transform-origin: 45%;
  fill: #2bbf89;
  width: 16px;
  margin-right: 1px;
}
.pair_downarrow svg {
  fill: #f96c5a;
  transform-origin: 45%;
  transform: rotate(90deg);
  width: 16px;
  margin-right: 1px;
}
.bottab_fullwidth {
  margin: 0;
  width: 100%;
}
button.primary_boder_btn {
  color: #a2a7b9;
  border: 1px solid #333545;
  background-color: #231f30;
  border-radius: 8px;
  min-height: 48px;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
}
.modify_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.modify_disabled.inputgrp_box input {
  padding-left: 30px !important;
}
.modify_disabled.inputgrp_box label {
  left: 30px;
}
.modify_disabled.inputgrp_box .input-group::before {
  content: "";
  background: url("../images/lock.png");
  width: 16px;
  height: 16px;
  position: absolute;
  left: 8px;  
  top: 15px;
  z-index: 1;
}
.modify_disabled.inputgrp_box.center .input-group::before {
  left: 40px;
}
.stop_bot_panel {
  display: grid;
  row-gap: 16px;
}
.stop_bot_single  {
  padding: 11px 12px;
  border: 1px solid rgba(129,140,153,.2);
  border-radius: 8px;
  background: #2c273d;
}
.stop_bot_single p {
  color: #a2a7b9;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
.stop_bot_single label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
.card-body-pb {
  padding-bottom: 120px !important;
}
.pagination button.page-link {
  background-color: #231f30;
  border-color: #2c283d;
  color: #fff;
  box-shadow: none !important;
}
.pagination button.page-link:disabled {
  color: rgb(255 255 255 / 30%);
}
ul.pagination {
  align-items: center;
  justify-content: flex-end;
}
.error_msg {
  position: absolute;
  background: #2b273c;
  border: 1px solid #7f7474;
  font-size: 12px;
  color: #ff605b !important;
  border-radius: 5px;
  padding: 5px;
  z-index: 1000;
  bottom: calc(100% + 5px );
}
.error_msg_bottom {
  position: absolute;
  background: #2b273c;
  border: 1px solid #7f7474;
  font-size: 12px;
  color: #ff605b !important;
  border-radius: 5px;
  padding: 5px;
  z-index: 1000;
  top: calc(100% + 3px);
}
.red_border {
  border: 1px solid #ff605b !important;
}
.border_top {
  border-top: 2px solid #231f30;
}
.strategy_filter {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 10px;
  margin-top: 15px;
}
.strategy_filter .search_inp_div {
  padding: 0;
  margin-top: 0;
  width: 100%;
}
.settings_button {
  background: transparent;
  border: none;
  color: #a2a7b9;
  font-size: 24px;
}
.backtest_period {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #333545;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.backtest_period p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #a2a7b9;
  margin-bottom: 0;
}
.backtest_periodbtn_grp button {
  color: #a2a7b9;
  padding: 9px 8px;
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  border-radius: 8px;
  background-color: transparent;
  border: none;
  transition: 0.2s all;
}
.backtest_periodbtn_grp button:hover {
  color: #fff;
}
.backtest_periodbtn_grp button.selected {
  color: #fff;
  background-color: #333545;
}
.filter_panel {
  display: grid;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  grid-row-gap: 16px;
}
.filter_panel_row label {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #a2a7b9;
  margin-bottom: 12px;
}
.filter_btn_grp {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 8px;
}
.filter_btn_grp button {
  padding: 12px;
  border-radius: 16px;  
  color: #a2a7b9;
  background-color: #333545;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  max-width: 100%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter_btn_grp button.selected {
  color: #fff;
  background-color: #0078ff;
}
.filter_inp_grp {
  display: flex;
  align-items: center;
  gap: 8px;
}
.backtest_modal .TVChartContainer {
  height: 320px !important;
}
.backtest_modal .rdt_TableBody {
  height: 200px;
  overflow-y: auto;
}
.primary_modal.backtest_modal .modal-body {
  height: 80vh;
  overflow-y: auto;
}
.primary_modal.backtest_modal .primary_table .rdt_TableRow {
  min-height: 42px;
}
.rdt_TableCol_Sortable {
  overflow: unset !important;
}
.rdt_TableCell div,
.rdt_TableCol_Sortable div {
  white-space: normal !important;
  overflow: unset !important;
  text-overflow: unset !important;
}
.dash {
  background: #0078ff;
  width: 73px;
  position: absolute;
  height: 2px;
  bottom: -18px;
  left: -9px;
  transition: left .5s;
}
.leftside_menu a.active + a + a + a + .dash{
  left: 78px;
  width: 61px;
}
.leftside_menu a.active + a + a + .dash{
  left: 155px;
  width: 77px;
}
.leftside_menu a.active + a  + .dash{
  left: 250px;
  width: 65px;
}
.leftside_menu a.active + .dash{
  left: 330px;
  width: 106px;
}
.leftside_menu .nav-link{
  font-size: 15px;
  color: #fff;
  opacity: .5;
  font-weight: 500;
}
.leftside_menu .active .nav-link{
  opacity: 1;
}
.dark_bdr{
  border: 1px solid #515364 !important;
}
.ms{
  margin-left: .12rem;
}
.signal_bot_filter_flx .react-datepicker__day--disabled {
  color: #404457;
  pointer-events: none;
}

/* Media Queries */
@media (min-width: 576px) {
  .primary_modal#new_bot_modal .modal-dialog {
    max-width: 375px;
  }
  .primary_modal .modal-dialog {
    max-width: 400px;
  }
  .primary_modal.primary_modal_large .modal-dialog {
    max-width: 1000px;
  }

  .primary_modal.primary_modal_extra_large .modal-dialog {
    max-width: 1200px;
  }

  .primary_modal.primary_modal_medium .modal-dialog {
    max-width: 650px;
  }


}
@media (min-width: 992px) {
  .profileSideMenu.navbar-collapse {
    display: block;
    flex-basis: auto;
  }
  .walletSideMenu.navbar-collapse {
    display: block;
    flex-basis: auto;
  }
}
@media (max-width: 1366px) { 
  .batch_settings_modal_panel .coin_details .pair_table_bodyrow {
    padding-right: 30px;
  }
}
@media (max-width: 1199px) {
  .filter_flx_chg .react-datepicker {
    margin-left: 10px;
  }
  .port_folio .info_box {
   gap: 30px;
  } 
  .trade_main_left {
    width: 100%;
  }
  /* .trade_main_right {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  } */
  .trade_topbar {
    flex-wrap: wrap;
  }
  .trade_topbar > div {
    width: 30%;
    justify-content: flex-start;
    padding: 5px 30px;
  }
  .trade_topbar > div:first-child {
    background: transparent;
    border-radius: 0;
    padding: 5px 30px;
  }
  .trade_topbar > div:last-child {
    padding: 5px 30px;
  }
  .coin_details {
    /* min-width: 30%; */
    width: 100%;
  }
  .api_binding_tab .nav-item .nav-link {
    min-height: 180px;
    padding: 40px;
  }
  #share_page_modal {
    width: 70%;
  }
  .trade_main_wrapper {
    grid-template-columns: 100%;
    grid-template-areas:
    "column"
    "bots"
    "chart";
    grid-template-rows: 1fr;
  }
  .trade_chart {
    height: 500px;
  }
  .primary_table_full_height {
    height: auto;
  }
  .trade_main_settings.cardheadbg {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .filter_flx_chg .react-datepicker__day-name, 
  .filter_flx_chg .react-datepicker__day, 
  .filter_flx_chg .react-datepicker__time-name {
    width: 35px !important;
  }
  .filter_flx_chg .react-datepicker{
    padding: 0 !important;
    margin-left: 5px;
  }

  .port_folio .info_box { 
    flex-wrap: wrap;
    display: flex;
}
.port_folio .abv_flx{
  flex-direction: column;
  align-items: flex-start;
}
.port_folio .h_flx{
  flex-wrap: wrap;
}
.port_folio .h_flx p {
  margin-bottom: 10px;
}
  .topProfileDropdown {
    display: none;
  }
  .navbar-toggler {
    box-shadow: none !important;
    outline: 0 !important;
    padding: 0 !important;
    background: transparent !important;
  }
  .profileSideMenu {
    background: #231f30;
    border-radius: 0;
    padding: 15px;
    position: fixed;
    top: 0;
    opacity: 0;
    z-index: 99999;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.25s;
    width: 250px;
  }
  .profileSideMenu.collapse.show::before {
    background: rgb(0 0 0 / 60%);
    content: "";
    left: 0;
    min-height: 100%;
    position: fixed;
    top: 0;
    transition: 0.25s;
    width: 100%;
    z-index: -1;
  }
  .light_theme .profileSideMenu.collapse.show::before {
    background: rgb(233 233 233 / 80%);
  }
  .light_theme .profileSideMenu {
    box-shadow: 0 0 8px 4px rgb(0 0 0 / 30%);
  }
  .light_theme #mobileMenuDrop a:hover,
  .light_theme #mobileMenuDrop a.active {
    background: #008ccc;
    color: #fff;
  }
  .light_theme #mobileMenuDrop ul li a {
    background: #d7d7d7;
    color: #333;
  }
  .light_theme #mobileMenuDrop a:hover span,
  .light_theme #mobileMenuDrop a.active span {
    filter: unset;
  }
  .profileSideMenu {
    left: -218px;
  }
  .profileSideMenuBottom ul.navbar-nav.topProfileDropdown {
    padding-top: 30px;
  }
  .profileSideMenu.collapse.show {
    left: 0;
    opacity: 1;
  }
  .profileSideMenuTop {
    position: relative;
  }
  .profileSideMenuBottom {
    position: relative;
  }
  .visuallyHidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  .hamburger {
    margin: 0 auto;
    width: 22px;
    height: 30px;
    position: relative;
    cursor: pointer;
    z-index: 99;
  }
  .hamburger .bar {
    padding: 0;
    width: 22px;
    height: 2px;
    background-color: #ffffff;
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute;
  }
  .bar1 {
    top: 6px;
  }
  .bar2,
  .bar3 {
    top: 12.5px;
  }
  .bar3 {
    right: 0;
  }
  .bar4 {
    bottom: 0;
  }
  .hamburger2 .bar2,
  .hamburger3 .bar3,
  .hamburger4 .bar4 {
    top: 12.5px;
  }
  .hamburger4 .bar5 {
    bottom: 10px;
  }
  .hamburger4 .bar {
    transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
  }
  .hamburger4 .bar2 {
    width: 1px;
    transform: rotate(90deg);
    left: 12.5px;
  }
  .hamburger4 .bar3 {
    width: 1px;
    left: 12.5px;   
  }
  .profileSideMenuBottom .navbar-toggler {
    position: absolute;
    top: -100px;
    left: 130px;
  }
  .profileSideMenuBottom .hamburger4 > .bar1 {
    top: 12.5px;
    background-color: #fff;
    display: none;
  }
  .profileSideMenuBottom .hamburger4 > .bar2 {
    left: 0px;
    width: 20px;
    transform: rotate(45deg);
  }
  .profileSideMenuBottom .hamburger4 > .bar3 {
    left: 0;
    width: 20px;
    transform: rotate(-45deg);
  }
  .profileSideMenuBottom .hamburger4 > .bar4 {
    background-color: transparent;
  }
  .profileSideMenuBottom .hamburger4 > .bar5 {
    bottom: 12.5px;
    background-color: transparent;
  }
  /* .walletSideMenu {
    position: fixed;
    width: 100%;
    z-index: 9;
    bottom: 0;
    background: #07071a;
    left: 0;
    padding: 15px;
  }
  .walletSideMenu .walletSideMenuBottom .navbar-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    text-align: center;
  }
  .walletSideMenu .walletSideMenuBottom .navbar-nav .nav-item a{
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .walletSideMenu .walletSideMenuBottom .navbar-nav .nav-item a span{
    display: block;
    margin-bottom: 7px;
    margin-right: 0;
  }
  .mobile-bottom-space {
    margin-bottom: 130px;
  } */
  nav.trade_menu ul.topProfileDropdown {
    display: block;
  }
  .trade_sidemenu .profileSideMenuBottom .navbar-toggler {
    top: -5px;
    left: 190px;
    border: none;
  }
  .trade_sidemenu li.nav-item {
    padding: 5px 0 !important;
  }
  .topProfileDropdown_mobile {
    display: flex !important;
  }
  .d_m_none {
    display: none !important;
  }
  .d_d_none {
    display: block !important;
  }
  .trade_sidemenu li.nav-item img {
    margin-right: 12px;
    filter: brightness(0) invert(1);
  }
  .light_theme .trade_sidemenu li.nav-item img {
    filter: brightness(1) invert(1) contrast(0);
  }
  .demo_list label {
    padding: 8px 0;
  }
  .demo_list label:hover {
    background: transparent !important;
  }
  /* .trade_main_left_top {
    flex-wrap: wrap;
  } */
  .coin_details {
    min-width: 100%;
  }
  .trade_chart {
    margin-top: 10px;
  }
  nav.trade_menu ul.topProfileDropdown li:nth-child(1) {
    padding: 0;
  }
  .batch_settings_modal_panel {
    flex-wrap: wrap;
  }
  .batch_settings_modal_panel .coin_details {
    min-width: 100%;
    margin-right: 0;
  }
  .batch_settings_modal_right {
    width: 100%;
    margin-top: 30px;
  }
  #batch_setting_modal .modal-dialog {
    min-width: inherit;
  }
  .walletSideMenu {
    margin-bottom: 15px;
  }
  .menu_round_btn {
    background: transparent;
  }
  /* .trade_chart {
    bottom: 0;
    overflow: auto;
    position: fixed;
    right: -100%;
    top: 0;
    transition: right 0.5s ease;
    width: 100%;
    z-index: 2200;
    background: #111;
    padding: 10px;
    margin-top: 0;
  }
  .trade_chart.show {
    right: 0;
  } */
  body.filter_overlay {
    position: relative;
    overflow: hidden;
  }
  .chart_close {
    font-size: 24px;
  }
  body.filter_overlay::after {
    content: "";
    background-color: #000;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1500;
  }
  .chart_mobile_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .chart_mobile_head h5 {
    margin: 0;
    font-weight: 600;
  }
  .light_theme .trade_chart {
    background: #f3f3f3;
  }
  .light_theme .chart_mobile_head {
    color: #000;
  }
  .leftside_menu {
    display: none;
  }
  .new_bot_btn svg {
    margin-left: 0;
  }
  .bot_plan_details {
    background: transparent;
    margin-right: 0;
    padding: 0;
  }
  nav.trade_menu ul.topProfileDropdown li {
    padding: 0 10px;
  }
  .new_bot_btn {
    background: transparent;
    border: none;
    color: #fd9827;
  }
  .new_bot_btn svg {
    color: #fd9827;
  }
  .bot_plan_details {
    gap: 2px;
  }  
}
@media (max-width: 767px) {
  .custom_modal .modal-content{
    width: auto;
  }
  .drpdwn_box .dropdown ,
  .inputgrp_box .input-group{
    width: 290px;
}
  .api_binding_tab .nav-item .nav-link {
    min-height: 140px;
    padding: 20px;
  }
  .api_binding_tab {
    gap: 5px;
  }
  .api_binding_card {
    padding: 15px;
  }
  .flexTitle {
    display: block;
  }
  .chartTitle {
    display: block;
  }
  .flexTitle .dashboardTabs {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .dashboardTabs li {
    max-width: unset;
  }
  .btn.btn-green-big {
    font-size: 16px;
    min-width: auto;
    padding: 5px 30px;
  }
  .form-card {
    padding: 0 15px 15px 15px;
  }
  body {
    font-size: 16px;
  }
  .referral_panel {
    padding: 50px 15px;
  }
  .referral_panel .input-group {
    max-width: 80%;
  }
  .referral_panel h3 {
    font-size: 24px;
  }
  .share_modal .modal-dialog {
    max-width: 95%;
    margin: 0 auto;
  }
  .dashboardTwoColumn .row:first-child > .col-md-6:nth-child(2) {
    margin-top: 30px;
  }
  #share_page_modal {
    width: 90%;
  }
  .profileTitleFlex {
    display: flex !important;
  }
  .info_alert p {
    max-width: 80%;
  }
  .flex-ul ul li {
    width: 100% !important;
  }
  .flex-ul ul li:nth-child(odd) {
    margin-right: 0 !important;
  }
  .flex-ul ul li:nth-child(even) {
    margin-left: 0 !important;
  }
}
@media (max-width: 579px) {
  .react-tooltip {
    width: 320px;
  }
  .trade_topbar > div:first-child,
  .trade_topbar > div,
  .trade_topbar > div:last-child {
    padding: 12px;
  }
  .trade_topbar > div {
    width: 50%;
  }
  .batch_settings_btn_grp button {
    font-size: 16px;
  }
  .batch_settings_btn_grp button img {
    display: none;
  }
  .new_bot_panel > a {
    padding: 10px 15px;
  }
  nav.trade_menu ul.topProfileDropdown li {
    padding: 0 10px 0 0;
  }
  .api_binding_tab .nav-item .nav-link {
    min-height: 100px;
    padding: 10px;
  }
  .api_binding_card_title {
    display: block;
  }
  .referral_panel .input-group {
    max-width: 100%;
  }
  .referral_panel h4 {
    margin: 0 0 15px;
    display: block;
  }
  .share_modal .modal-body .share_modal_body_top {
    display: block;
  }
  .share_modal .modal-body .share_modal_body_left,
  .share_modal .modal-body .share_modal_body_right {
    width: 100%;
  }
  .share_modal_body_right img {
    width: 150px;
    margin-bottom: 30px;
  }
  .social_share_list li {
    margin-right: 10px;
  }
  .revenue_flex {
    justify-content: flex-start;
  }
  .pair_table_bodyrow_top > div:first-child h3 {
    font-size: 13px;
  }
  .pair_table_bodyrow_top > div:first-child h3 span {
    font-size: 11px;
  }
  .pair_table_bodyrow_top img {
    width: 20px;
  }
  .per_change {
    font-size: 10px;
  }
  #pairTab {
    margin-bottom: 15px !important;
  }
  .member_box {
    flex-wrap: wrap;
  }
  .member_box > div:first-child {
    width: 100% !important;
  }
  .member_box > div {
    width: 50%;
    margin-bottom: 15px;
  }
  .wbCard {
    margin-bottom: 30px;
  }
  .flexTitle .filterSelect {
    margin-top: 15px;
    width: 100%;
    max-width: 100% !important;
  }
  .userContent {
    padding: 10px;
  }
  .auth_otp_btn {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
  }
  .auth_otp_btn button.input-group-text {
    width: 100%;
  }
  .pair_table_bodyrow_bottom label {
    font-size: 12px;
  }
  .batch_settings_modal_panel .coin_details .form-check-input[type="checkbox"] {
    margin-right: 6px;
    margin-top: 14px;
    width: 12px;
    height: 12px;
  }
  .batch_settings_modal_panel .coin_details .pair_table_bodyrow {
    padding-right: 0;
  }
  .revenue_flex {
    display: block;
  }
  .revenue_flex h4:nth-child(1) {
    margin-right: 0;
    margin-bottom: 8px;
  }
  .userTopMenu .navbar-brand img {
    width: 110px;
  }
  .chart_custom_data {
    display: none;
  }
}
@media (max-width: 401px) {
  .navbar-brand img {
    width: 190px;
  }
  .form-control,
  .input-group-text {
    font-size: 14px;
  }
  .input-group-text {
    padding: 5px;
  }
}
@media (max-width: 340px) {
  .api_binding_tab .nav-item .nav-link {
    min-height: 80px;
    padding: 10px;
  }
  .share_modal_body_right img {
    width: 120px;
  }
  .navbar-brand img {
    width: 150px;
  }
  .form-card {
    padding: 0 5px 15px 5px;
  }
  .input-group-text {
    padding: 3px;
  }
}

/* Manual Trade CSS Starts Here */
.action-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.af-left p{
  margin-bottom: 0;
}
.af-right{
  display: inline-flex;
  align-items: center;
}
.af-right > *{
  margin-left: 15px;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input-tm {
  float: left;
  margin-left: -1.75em;
}

.form-check-input-tm {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.125em;
  margin-right: .425rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input-tm {
    transition: none;
  }
}
.form-check-input-tm[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input-tm[type=radio] {
  border-radius: 50%;
}
.form-check-input-tm:active {
  filter: brightness(90%);
}
.form-check-input-tm:focus {
  border-color: #e0ce58;
  outline: 0;
  box-shadow: none;
}
.form-check-input-tm:checked {
  background-color: #e0ce58;
  border-color: #e0ce58;
}
.form-check-input-tm:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input-tm:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input-tm[type=checkbox]:indeterminate {
  background-color: #e0ce58;
  border-color: #e0ce58;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input-tm:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.btn-secondary,
.btn-primary{
  background-color: #af7707;
  color: #ffffff;
  border-color: #af7707;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-primary:hover,
.btn-primary:focus{
  background-color: #af7707;
  color: #ffffff;
  border-color: #af7707;
  opacity: .85;
}
.btn-secondary.dropdown-toggle::after{
  color: #ffffff;
}
.currenct-name{
  margin-bottom: 0;
}
.rdt_TableBody .btn-primary{
  background-color: #af7707;
  color: #ffffff;
  border-color: #af7707;
}
.rdt_TableBody .btn-primary:hover,
.rdt_TableBody .btn-primary:focus{
  background-color: #af7707;
  color: #ffffff;
  border-color: #af7707;
  opacity: .85;
}
.form-check-label{
  vertical-align: middle;
}
.balance-trade-manual h5{
  font-size: 16px;
}
.balance-trade-manual h5 span{
  color: #f0df72;
}
.balance-trade-manual h2{
  font-size: 22px;
  margin-bottom: 15px;
}
.balance-trade-manual h2 span{
  font-size: 16px;
  font-weight: 400;
}
.balance-trade-manual h6{
  font-size: 16px;
}
.balance-trade-manual h6 span{
  font-size: 14px;
}
.flex-ul ul{
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.flex-ul ul li{
  width: calc(50% - 20px);
  padding: 15px 0;
  border-bottom: 1px solid #2b273b;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.flex-ul ul li:nth-child(odd){
  margin-right: 20px;
}
.flex-ul ul li:nth-child(even){
  margin-left: 20px;
}
.flex-ul ul li label{
  font-weight: 600;
}
.border-box{
  padding: 15px;
  border: 1px solid #2b273b;
}
.info-modal-title h2{
  font-size: 22px;
}
.info-modal-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-modal-title h6{
  font-size: 16px;
}
.info-modal-title h6 label{
  font-weight: 700;
}
.info-modal-title h6 i{
  font-size: 18px;
}
.flex-button{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.market-type .tab{
  display: flex;
  gap: 10px;
  align-items: center;
}
.market-type .tab > div{
 width: 50%;
 position: relative;
 padding: 0;
 margin: 0;
}
.market-type .tab input{
 border:1px solid #333545;
 background-color: rgba(0, 119, 255, 0);
 font-size: 12px;
 font-weight: 500;
 line-height: 14px;
 width: 100%;
 padding: 15px;
 border-radius: 5px;
 text-align: center;
 box-shadow: none !important;
 width: 100%;
 margin: 0;
 background-image: none;
 cursor: pointer;
 z-index: 1;
 position: relative;
}
.market-type .tab input:checked{
 border:1px solid #af7707;
 background-color: rgba(175,119,7,.2);
 box-shadow: none !important;
 background-image: none;
}
.market-type .tab label{
 position: absolute;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 margin: auto;
 width: fit-content;
 height: fit-content;
 font-size: 12px;
 font-weight: 500;
 z-index: 0; 
}
.balance-trade-manual{
  margin-top: 20px;
}
.manual-buy-sell .accordion,
.manual-buy-sell .accordion-item{
  background: transparent;
  border: 0;
  
}
.manual-buy-sell .accordion-body{
  padding: 0;
}
.manual-buy-sell .accordion-body label{
  color: #ffffff;
  margin-bottom: 0;
}
.manual-buy-sell .accordion-button:not(.collapsed){
  background: transparent;
  border: 0;
  box-shadow: none !important;
}
.manual-buy-sell .accordion-button{
  padding-left: 0;
  padding-right: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 0;
  margin-bottom: 10px;
  background: transparent;
  color: #ffffff;
  box-shadow: none !important;
}
.manual-buy-sell .accordion-button::after {
  background: transparent !important;
  color: #fff !important;
  border: 0 !important;
}
.manual-buy-sell .accordion-button:not(.collapsed)::after {
  content: "\F2EA";
  font-family: "bootstrap-icons" !important;
  color: #ffffff !important;
}
.flex-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.border-bottom{
  border-bottom: 1px solid #333545 !important;
}
.market-type{
  margin-top: 20px;
  margin-bottom: 20px;
}
.mt-place-order .pair_dropdown{
  height: 44px;
  position: relative;
}
.mt-place-order .pair_dropdown::after{
  content: "\F229";
  font-family: "bootstrap-icons" !important;
  color: #ffffff !important;
  position: absolute;
  font-size: 11px;
  right: 15px;
  top: 32%;
}
.mt-place-order .form-control{
  background: #1d1a28;
  color: #ffffff;
}
.mt-coin-list{
  margin: 0;
  padding: 0;
  list-style: none;
}
.mt-coin-list li{
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  padding: 7px 10px;
  transition: .25;
}
.mt-coin-list li:hover{
  background-color: #2c273d;
}
.mt-place-order .chk_box input:checked {
  background-color: #af7707;
}
.warning-modal-text{
  padding-bottom: 30px;
}
.big-center-icon i{
  font-size: 100px;
  color: #ff3636;
}
.flex-center-button{
  display: flex;
  justify-content: center;
  margin: 25px 0 15px;
}
.flex-center-button .btn-primary:hover{
  background: #af7707;
  opacity: .85;
  border-color: #af7707;
}
.flex-center-button .btn-secondary{
  background: transparent;
  border: 1px solid #af7707;
}
.flex-center-button .btn-secondary:hover{
  background: transparent;
  border: 1px solid #af7707;
  opacity: .85;
}
.currenct-name{
  cursor: pointer;
}
.warning-modal-text h3{
  font-size: 24px;
  text-transform: capitalize;
}
.mt-place-order .btn-primary:hover,
.mt-place-order .btn-secondary:hover{
  background: #af7707;
  opacity: .85;
  border-color: #af7707;
}
.batchText{
  background: #af7707;
  padding: 4px 15px;
  border-radius: 3px;
}



.filter_flx h6{
  font-size: 15px;
  color: rgba(255,255,255,.7);
}
.filter_flx .green{
  font-weight: 500 !important;
  color: #2bbf89 !important;
  font-size: 16px !important;
}
.filter_flx .react-datepicker-wrapper input{ 
  border: 0;
  background: #14121d;
  font-size: 15px;
  font-weight: 500;
  width: 110px;
}
.filter_flx  .bg{
  background: #14121d;
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.filter_flx .trade_fee{
  width: 133px;
}
.filter_flx .trade_fee .inputgrp_box {
  border: 1px solid #231f30;
  border-radius: 8px;
  transition: .2s;
}
.filter_flx .trade_fee .inputgrp_box:hover {
  border: 1px solid #0078FF;
}

.filter_flx .react-datepicker-wrapper:first-child input{
  border-radius:  8px 0 0 8px ;
}
.filter_flx .react-datepicker-wrapper:last-child input{
  border-radius: 0   0;
}
.filter_flx .date_pic {
  border-radius: 8px;
}
.filter_flx .date_pic .inputgrp_box{
  border:1px solid #231f30;
  border-radius: 8px;
}
.filter_flx .date_pic .inputgrp_box:hover{
  border:1px solid #333545;
}

/* filter_flx_chg  */
.filter_flx_chg .react-datepicker__header{
  background-color: #221f2f;
}
.filter_flx_chg .react-datepicker__month-container, .filter_flx_chg .react-datepicker__time-list{
  background: #221f2f;
}
.filter_flx_chg .react-datepicker {
  border: 0;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,.45);
  padding: 0 7px;
  background: #221f2f;
  /* margin-right: -55px; */
}
.filter_flx_chg .react-datepicker__day-name,
.filter_flx_chg .react-datepicker__day,
.filter_flx_chg .react-datepicker__time-name{
  width: 38px;
  line-height: 40px;
  font-size: 14px;
}
.filter_flx_chg .react-datepicker__day{
  font-weight: 600;
}
.filter_flx_chg .react-datepicker__day-name{
  color: #404457;
  font-size: 13px;
  font-weight: 500;
}
.filter_flx_chg .react-datepicker__year-read-view--down-arrow,
.filter_flx_chg .react-datepicker__month-read-view--down-arrow,
.filter_flx_chg .react-datepicker__month-year-read-view--down-arrow,
.filter_flx_chg .react-datepicker__navigation-icon::before{
  border-color: #7f7f7f;
  border-style: solid;
  border-width: 2px 2px 0 0;
}
.filter_flx_chg .react-datepicker__week{
  margin: 10px 0;
}
.filter_flx_chg .react-datepicker__header{
  border-bottom: 0px solid #343546;
}
.filter_flx_chg .react-datepicker__navigation{
  top: 19px;
}
.filter_flx_chg .react-datepicker__current-month {
  padding: 10px 0 20px;
  font-weight: 700;
  font-size: 16px;
}
.filter_flx_chg .react-datepicker__day--disabled {
  color: #404457;
  pointer-events: none;
}
.filter_flx_chg .react-datepicker-popper{
  z-index: 20;
}

/* .filter_flx_chg .react-datepicker__day:hover, 
.filter_flx_chg .react-datepicker__time-list-item:hover,
.filter_flx_chg .react-datepicker__day--in-range {
  background-color: #0078ff !important;
} */

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  border-bottom-color: #221f2f !important;
}


.date_cal .dat{
  margin-right: -40px;
}
.date_cal  input{
  width: 40px !important;
  height: 40px;
  padding: 0;
  margin-right: 30px;
  margin-top: -13px;
  cursor: pointer !important;
  opacity: 0;
}
.date_cal .react-datepicker-wrapper{
  margin-right: -40px;
}
.date_cal .svg_i{
  width: 42px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-color: #323545;
  background-color: #323545;
  min-height: 32px;
  padding: 4px 8px;  
  border: 1px solid;
  border-color: #323545;
  cursor: pointer;
  border-radius: 8px;
}
.date_cal svg{
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
.dash {
  display: none;
}
.leftside_menu a.nav-item {
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;
  margin-bottom: -21px;
}
.leftside_menu a.nav-item.active {
  border-bottom: 2px solid #0078ff;
  padding-bottom: 5px;
  margin-bottom: -21px;
}
.delete_cal{
  width: 42px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-color: #323545;
  background-color: #323546;
  min-height: 32px;
  padding: 4px 8px;
  border: 1px solid;
  border-color: #323545;
  cursor: pointer;
  border-radius: 8px;
  cursor: pointer;
}
.delete_cal img{
  filter: invert(1);
  height: 21px;
  width: auto;
  margin-left: 3px;
}